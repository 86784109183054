import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#434343",
    "col1n": "#666666",
    "col2d": "#BFBFBF",
    "col2n": "#D9D9D9"
}
function ShiMot112({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M30.85 11.65q2.3-4.55 1-7.35-1.3-2.85-1.65-4.3-.2.05-.4.15-.4.25-.7.5-.1.05-.2.15-4.55 2.4-9.35 3.8-4.75 1.3-7.65 1.5-2.95.2-3.75.15-.8-.1-3.8-.35-3.05-.25-4.1.9-.45.45 0 2.15.8 2.65 3.9 8.6 4.5 8.7 9.45 14.55l.6.7q.25.3.5.55 3.05 3.3 6.7 4.2 3 .7 6.45-.05 4.75-1.6 7.3-5.3.55-.85 1.05-1.8.3-.65.55-1.25-.4-4.45-1.2-6.35-.85-1.95-.85-2.1-.05-.25-.55-2.15-.5-2-3.05-5-.5.4-1 .6-2.1.65-4.85-2.35-.55-.5-1.1-1.15 2.7 1.7 5.65 1.25.5-.1 1.05-.25m-6.4 12.1q0-.05.05-.05 4.05-1.25 7.5 1.85l.5.45q.4.45.85.9.2.2.55.3l-.25-.05q-.6-.15-2.85-.55-2.25-.45-5.55.45-.1 0-.2.05-3.55 1.05-6 3.4-2.45 2.35-1.5 1.35.05-1 .55-2.6 2.1-4.25 6.35-5.5m-6.4-9.9q-1.45 5.95-5.4 4.25-.85-.3-1.85-1.05 5.2-.3 7.65-4.75-.2.9-.4 1.55z"
                    id="ShiMot112__Symbol_58_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M11.3 11.65q1.85-4.7.65-7.4-1.2-2.75-1.3-4.25-.2.05-.4.15-.4.25-.7.5-.1.05-.2.15Q4.8 3.2 0 4.6l4.9 19.15q0-.05.05-.05 4.05-1.25 7.5 1.85l.5.45q.4.45.85.9.2.2.55.3l-.25-.05q-.6-.15-2.85-.55-2.25-.45-5.55.45-.1 0-.2.05l1.25 4.45h.05l1.5 5.95q4.75-1.6 7.3-5.3.55-.85 1.05-1.8.3-.65.55-1.25-.1-3.55-1.2-5.55-1.1-2-1.25-2.35-.2-.4-.75-2.1-.55-1.65-2.45-5.6-.5.4-1 .6-2.1.65-4.85-2.35-.55-.5-1.1-1.15 2.7 1.7 5.65 1.25.5-.1 1.05-.25z"
                    id="ShiMot112__Symbol_61_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M38.85 10.5q.3-1.7-.1-2.05Q37.6 7.3 32.45 8q-1.05.2-2.1.25-.6.1-1.2.05-5 .15-9.85-1-4.75-1-9.25-3.2-.3-.15-.55-.2-1.3-.7-2.45-1.5Q2.5-.4 1.05.1-.1.45 0 8.15q0 1.55.05 3.4.15 4.35.6 8.25.1.95.25 1.9.55 3.95 1.45 7.4.1.45.2.85.1.45.25.8Q4 34.7 6.55 37.3q.2.2.45.4 2.4 2 5.8 2.95 4.9.9 8.7-1.25 2.35-1.25 4.25-3.6.2-.25.5-.6.25-.35.55-.75 4.6-6.15 8.7-15.1 2.7-6.15 3.35-8.85m-11.2 8.1q.5.85.75 2.1-2.7-2.2-5.5-2.3-1.05-.1-2.1.15-.55.15-1.1.35.7-.55 1.25-.9.55-.4 1-.6 3.95-2.35 5.7 1.2M6.1 15.75q.7-1 1.4-1.55 2.75-2.5 5.55 1.05.35.45.8 1.2.45.6.9 1.3-.55-.5-1.1-.9-.85-.5-1.75-.95-2.6-1-5.8-.15m-.45 6.35l-.1.5v-.65q.05.05.1.15m8.75 8.1q.15.05.3.05 3.2.6 6.2-.1l.4-.1q.75-.2 1.3-.35.35-.1.65-.15h.05l-.2.3q-.55.8-1.55 1.75l-.4.3q-3.35 2.15-7 1.65-.4-.05-.8-.15h-.05q-3.15-.65-4.85-3.3-.5-.85-.9-1.9l-.2-.65q-.05-.2-.1-.35-.1-.4-.25-.85-.05-.25-.3-.5l.2.15q.45.45 2.2 1.9.3.2.6.45 1.75 1.1 4.55 1.8.05.05.15.05z"
                    id="ShiMot112__Symbol_60_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M26.05 3.2q.3-1.7-.1-2.05Q24.8 0 19.65.7q-1.05.2-2.1.25-.6.1-1.2.05-5 .15-9.85-1L4 12.65q-1.5 7.55-1.65 7.5l-.45 2.8q3.2.6 6.2-.1l.4-.1q.75-.2 1.3-.35.35-.1.65-.15h.05l-.2.3q-.55.8-1.55 1.75l-.4.3q-3.35 2.15-7 1.65L0 33.35q4.9.9 8.7-1.25 2.35-1.25 4.25-3.6.2-.25.5-.6.25-.35.55-.75 4.6-6.15 8.7-15.1 2.7-6.15 3.35-8.85M10.1 11.1q-1.05-.1-2.1.15-.55.15-1.1.35.7-.55 1.25-.9.55-.4 1-.6 3.95-2.35 5.7 1.2.5.85.75 2.1-2.7-2.2-5.5-2.3z"
                    id="ShiMot112__Symbol_59_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiMot112__Symbol_58_0_Layer0_0_FILL"
                transform="translate(157.55 266.8)"
                id="ShiMot112__col1n"
            />
            <use
                xlinkHref="#ShiMot112__Symbol_61_0_Layer0_0_FILL"
                transform="translate(177.1 266.8)"
                id="ShiMot112__col1d"
            />
            <use
                xlinkHref="#ShiMot112__Symbol_60_0_Layer0_0_FILL"
                transform="translate(187.75 258.65)"
                id="ShiMot112__col2n"
            />
            <use
                xlinkHref="#ShiMot112__Symbol_59_0_Layer0_0_FILL"
                transform="translate(200.55 265.95)"
                id="ShiMot112__col2d"
            />
        </g></g>
    )
}
export default ShiMot112
