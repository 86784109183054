import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig047({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#434343"
                    d="M293.45 267.25q.365-.725-.1-.15l.1.15z"
                    id="HaiHig047__Layer8_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M55 48.45q-5.05-2.9-2.75-4.6.2-.2.45-.35-.1-.5-.15-1.25-.3-4.15-1.25-4.95-6.7-11.35-10-15.1-3.2-3.7-7.1-6.25-4.35-2.85-9.5-4.2-9.25-2.5-15.9-5.95l-.3-.2Q4 3.2 0 0q.1.2.15.45 2.9 8.5 6.4 16.2 1.1 2.5 2.3 4.9.65 1.25 10.9 8.05 10.2 6.8 2.4 13.65 1.4 1.85 2.85 3.6.85 1 1.6 2.05 3.5 4.65 6.1 9.35.8 1.4 1.5 2.85 2.9 5.85 4.45 11.9.2.8.4 1.65 6.4 6.95 4.85 10.65-1.55 3.7-2.65 6.1-1.15 2.35-1.7 5.35-.1.65-.25 1.35-.1.55-.2 1.15l-.4 1.3q-.2.85-.5 1.7 0 .05-.05.15-.05.15-.15.35l-.6 2q0 .1-.05.25-.35 1.2-.65 2.35l-.1.3q-.05.3-.15.65-.8 4.4-1.25 9.05 0 .1-.05.2 0 .5 1.2 4.35 1.2 3.85 1.9 6.25v.05q.5.15 1.1.4 4.45 1.8 1.25 2.75-.35.1-.6.2-.1.4-.3.95 3.1 7.35.35 1.6-.3-.55-.55-1.1-.35 1-.95 2.4-2.65 6-2.45 7.2.05.3.1.65l.2 1.5q.3 1.65.6 3.4 1.2 6.25 3.05 12.8.05.15.1.35.4 1.5.8 3.1.25 1.05.45 2.15.25 1.2 3.6 4.95 2.85 3.2.95 1.95.75 1.15 1.35 3 1.3 3.85-1.45 8.75-2.8 4.85-2.9 6.75-.1 2.35-.3 4.85-.35 4.35-.9 9-.45 3.75-1.1 7.75-.2 2.1-1.4 5.7 5.65-2.9 8.55-8.45.1-.15.15-.25v-.05q.1.55.15 1.2l.1 1.2q.25 2.7.15 4.95-.25 5.15-2.25 8 .8-.3 1.55-.6 3.45-2.2 6.25-4.95l1.1-1.1q7.2-7.75 9.35-19.5.3-1.55.5-3.15v-.3q.4-2-2.45-10.65-1.45-4.4-1.35-6.65-.2.25-.4.6-2.8 4.3.75-3.75 3.5-8.05 3.35-9.5-.2-3.1-.3-5.2-.55-4.55-1.05-7.8-.15-1.15-.35-2.15-.15-1-.3-1.8-.1-.75-.25-1.35-.45-2.1-1.4-5.3-.05-.15-5-4.7-4.95-4.6-4.05-6.5.45-1.05.05-1.15.3-.2 1.55.45l.3.2q.35-.9 1.35-2.5 2.25-3.75 2.2-3.85V123.95 123q-.05-2.95.25-6.45v-.4q.2-2 .95-5.1.3-1.35.75-2.95l.2-.7q.3-1.05.65-2.15.1-.25-.65-6.15-.8-5.95.75-8l.1-.1q-.2-.25-.35-.5-.45-.95.95-1.8.45-.3.9-.1.45.15.85-1.1.4-1.25 2.5-4.2.1-.55.1-1.05.25-3.15.25-6.35 0-1.45-.05-2.9-.25-6.4-1.55-12.15-.3-1.35-.65-2.6-.1-.35-.2-.65-.1-.5-.3-1-.15-.5-1.5-2.85-1.4-2.4-6.45-5.3M46.5 201.5h-.05v-.15q.05.05.05.15z"
                    id="HaiHig047__Symbol_155_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M49.55 191.5q.05-.6.05-1.15-1.35-1.2-2.3-2.15-1.65 1.1-4.5 3.3-.1 2.35-.3 4.85-.35 4.35-.9 9-.45 3.75-1.1 7.75-.2 2.1-1.4 5.7 5.65-2.9 8.55-8.45.4-2.55.7-5 .55-4.65.9-9 .2-2.5.3-4.85m-3.2 9.55h-.05v-.15q.05.05.05.15M43 144.35q-.25.55-.55 1.15-3.85-1.7-5.85-2.95-.3-.2-.65-.4.05.3.1.65l.2 1.5q.3 1.65.6 3.4 1.2 6.25 3.05 12.8.05.15.1.35.4 1.5.8 3.1.25 1.05.45 2.15l5.1 2.75q1.05-1.2 1.75-2.05l-.1-.7q-.2-1.1-.45-2.15-.4-1.6-.8-3.1-.05-.2-.1-.35-1.85-6.55-3.05-12.8-.3-1.7-.6-3.35m-3.85-46.7q-.1.55-.2 1.15l-.4 1.3q-.2.85-.5 1.7 0 .05-.05.15-.05.15-.15.35l-.6 2q0 .1-.05.25-.35 1.2-.65 2.35l-.1.3q-.05.3-.15.65-.8 4.4-1.25 9.05 0 .1-.05.2 4 3.1 6.2 4.45l.3.2q.05-.4.05-.75v-.2q.05-1.35.15-2.7.05-.5.05-1 .05-.1.05-.2.45-4.65 1.25-9.05.1-.35.15-.65l.1-.3q.3-1.15.65-2.35.05-.15.05-.25l.6-2q.1-.2.15-.35.05-.1.05-.15.25-.7.4-1.4l-6.05-2.75M8.7 21.1q1.95 1 3.7 1.9Q4.65 11.5 0 0q2.9 8.5 6.4 16.2 1.1 2.5 2.3 4.9m16.15 25.3q.85 1 1.6 2.05 3.5 4.65 6.1 9.35.8 1.4 1.5 2.85 2.9 5.85 4.45 11.9.2.8.4 1.65l7.35 3.1q-.25-1.55-.6-3.1-.2-.85-.4-1.65-1.9-7.5-5.95-14.75-2.25-4.1-5.25-8.1l-.2-.3q-5.2-2.1-6.7-3.2-2.55-1.9-5.15-3.4 1.4 1.85 2.85 3.6z"
                    id="HaiHig047__Symbol_156_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M56.15 147.85q-.1-1.65-.25-3.1-1.65 2.15-3.25 4.35L41 143.55q-.4.7-1.6 2.15-.7.85-1.75 2.05l-5.1-2.75q.25 1.2.45 2.45l.1.6q.15 1 .3 2.1.1.85.2 1.8.8 6.8.6 14.75 0 1.8-.1 3.7 2.85-2.2 4.5-3.3.95.95 2.3 2.15 2.35 2.1 5.8 5.1.35-.95 2.9-3.1 1.6-1.35 2-1.8.1.6 2.05 2.85L56 175v-.3q.4-2 .6-4 1.1-8.9-.2-19.2l-.05-.7q0-.3-.05-.6-.05-1.25-.15-2.35M26.3 96q0 .5-.05 1-.1 1.35-.15 2.7v.2q-.05.75-.05 1.55-.05.5-.05 1V105.25q.05 3.6.3 7.35.15 1.4.25 2.9.2 1.55.35 3.2.15 1.15.35 2.35.35.2.65.4 2 1.25 5.85 2.95.3-.6.55-1.15.85-1.85 1.05-2.8.15.05 4.45 2.25 5 2.5 10.8 5 0-.15.1-.2l.15-.05q.05-1.3.45-3 .5-2 .95-3.3l-.1-.3q-.45-2.3-1.1-4.9-.9-3.65-1.45-6.45-.15-.75-.3-1.45-.35-1.95-.5-3.4-.1-.75-.15-1.4.05-.05 0-.15V102.4q-1.05-.5-2-.95-5.2-2.45-6.85-3.15-.65 1.15-1.35 3.15-.2.5-.35 1-.05.3-.2.65-.8-.25-1.7-.65-.95-.45-2-1-.7-.35-1.4-.8l-.3-.2Q30.3 99.1 26.3 96m3.9-42.9q1.3 5.85 1.35 11.85.05 2.1-.1 4.25-.2 3-.75 6-.1.65-.25 1.35L40.5 81.1q.4-2.35.6-3.4.25.2 5.3 2.65 2.8 1.55 4.45 2.85l.6.5q.1-.25.15-.5.7-2.2 1.5-4.65.55-1.65 1-3.35.55-2 1-4.05.2-.95.4-1.95.3-1.55.5-3.1.1-.55.15-1.15.25-1.6.35-3.2-6.05-5.3-15.65-8.3v4.1q-.2-.1-.4-.1h-.05L30.2 53.1m14.05-35.65q-.85-.9-1.8-1.7-1.3-1.2-2.9-2.15-1.7-1.05-3.65-1.9-3.3-1.4-10.55-4-1.15-.4-2.4-.85.35.95.85 2.1.25.6.55 1.2-7.7-2.2-12.55-4.3-1.15-.5-2.7-1.25-2.3-1.1-5.4-2.7Q1.95 1 0 0q.65 1.25 1.25 2.5Q6.7 13 13.3 21.7q2.6 1.5 5.15 3.4 1.5 1.1 6.7 3.2l.2.1q3.7 1.4 9.2 3.3 0-.1-.05-.2-.75-2.5-.9-3.8h.25q3.55 1.25 6.45 2.3 2.95 1 6.8 2.55 3.85 1.5 7.3 3.5-.1-.5-.3-1l-.3-1q-1.4-4.25-3.45-7.6Q48.9 24 46 19.9l-.4-.6q-.75-1-1.35-1.85z"
                    id="HaiHig047__Symbol_157_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M39.75 147.45q-.15-.9-.35-1.75-.7.85-1.75 2.05l-5.1-2.75q.25 1.2.45 2.45l.1.6q.15 1 .3 2.1.1.85.2 1.8.8 6.8.6 14.75 0 1.8-.1 3.7 2.85-2.2 4.5-3.3.95.95 2.3 2.15.05-1.3.05-2.55.2-7.95-.6-14.75-.1-.95-.2-1.8-.15-1.1-.3-2.1l-.1-.6M26.3 96q0 .5-.05 1-.1 1.35-.15 2.7v.2q-.05.75-.05 1.55-.05.5-.05 1V105.25q.05 3.6.3 7.35.15 1.4.25 2.9.2 1.55.35 3.2.15 1.15.35 2.35.35.2.65.4 2 1.25 5.85 2.95.3-.6.55-1.15v-.05l-.2-1.5q-.05-.35-.1-.65-.2-1.2-.35-2.35-.15-1.65-.35-3.2-.1-1.5-.25-2.9-.25-3.75-.3-7.35v-.1-2.7q.05-.5.05-1v-.8l-.3-.2Q30.3 99.1 26.3 96m11.25-39.8l-7.35-3.1q1.3 5.85 1.35 11.85.05 2.1-.1 4.25-.2 3-.75 6-.1.65-.25 1.35l6.05 2.75.1-.3.4-1.3q.1-.6.2-1.15.15-.7.25-1.35.55-3 .7-6 .15-2.15.15-4.25-.05-4.4-.75-8.75M1.25 2.5Q6.7 13 13.3 21.7q2.6 1.5 5.15 3.4 1.5 1.1 6.7 3.2 0-.1-.05-.15Q14.85 18.8 3.7 1.9 1.95 1 0 0q.65 1.25 1.25 2.5z"
                    id="HaiHig047__Symbol_158_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#HaiHig047__Layer8_0_FILL" />
            <use
                xlinkHref="#HaiHig047__Symbol_155_0_Layer0_0_FILL"
                transform="translate(230.75 90.5)"
                id="HaiHig047__col1n"
            />
            <use
                xlinkHref="#HaiHig047__Symbol_156_0_Layer0_0_FILL"
                transform="translate(230.9 90.95)"
                id="HaiHig047__col1d"
            />
            <use
                xlinkHref="#HaiHig047__Symbol_157_0_Layer0_0_FILL"
                transform="translate(239.6 112.05)"
                id="HaiHig047__col2n"
            />
            <use
                xlinkHref="#HaiHig047__Symbol_158_0_Layer0_0_FILL"
                transform="translate(239.6 112.05)"
                id="HaiHig047__col2d"
            />
        </g></g>
    )
}
export default HaiHig047
