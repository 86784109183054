import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#0000BB",
    "col2n": "#FF0000"
}
function ShiSpe029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M143 3.15q-.3-.4-.55-.8-.9-1.2-1.75-2.35l-1.85 1.4q-.15.1-.25.25-3.2 2.85-14.15 12.45l-16.2 14.1L84.4 22q-3 .15-6 .1l-26.55 5.5L20.6 0q-.1.05-.15.15-1.1 1.45-2.1 3-1.9 2.7-3.6 5.55-.85 1.45-1.65 2.9-1.5 2.7-2.85 5.55-.5.95-.95 1.95-2.05 4.45-3.8 9.25l-.9 2.4q-.15.4-.3.85-.7 2.05-1.35 4.2-.85 2.85-1.65 5.8-.1.3-.15.6l-.7 2.7-.1.5H.4q-.15.5-.25 1-.05.15-.05.3L0 47q0 .1.1.2.2.15 1 .4 15.3 2.45 18.6 9.55l.25.25q.05 0 .05.05L17.2 69.2q-.55 2.1-1.05 4.2-.4 1.5-.75 3-.05.25-.1.45-.05.1-.05.15l-.15.4q1.1 1.6-.8 3.2v.2q-.05 0-.05.05-.15.05-.25.15-3.05 2.35-4.6 8.05 5.1 1.2 6.75 1.55 28.1 6.4 56.1 6.95.9 0 1.8.05 3.7-.3 5.7-3.45.6-1.25 1-2.75.1.3.15.6l.35 1.05q1.6 4.05 5.75 4.5l.4.05q.9-.05 1.8-.05h.65q27.55-.65 55.3-6.95 1.65-.35 6.85-1.55-1.2-4.6-3.1-6.35-1.95-1.8-2.05-1.8-.25.1-.5.15.2-.05.4-.15h.2l-.05-.2v-.1q-2.05-1.6-.8-3.2l-4.35-17.5h-.05l-.55-2.45h.05q5.8-10.9 20.25-10.1-.05-.15-.1-.35v-.05q-.05-.15-.05-.25l-.1-.3q-.15-.75-.35-1.5-.35-1.35-.65-2.7l-.2-.6-.4-1.6q-.75-2.7-1.6-5.3-.3-1-.6-1.95-.3-.8-.55-1.6-.1-.2-.15-.35-.45-1.25-.85-2.45-.9-2.4-1.85-4.75-1-2.3-2-4.5-1.8-3.95-3.85-7.6-1-1.85-2.1-3.6-.6-.95-1.15-1.9-.95-1.45-1.9-2.85z"
                    id="ShiSpe029__Symbol_389_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M25.3 48.8q-10.85-8.5-22-2.1l-.4.2q-.1.05-.15.1-4.3 2.65-1.85 9.35 1.5-1.3 3.3-2.15 1.5-.7 3.2-1.05.1-.05.2-.05.15-.05.35-.1.15-.05.3-.05l1.85-.2q5.05-.2 8.7 1.65l.2.1q2.1 1.1 3.7 2.9l.8-.15 1.8-8.45m123.55 28.6h-.05q-2.05.5-4.1.95-30.55 7-61.3 7-30.6 0-61.4-7-2.1-.45-4.15-.95l-.8 3.2v.25l-.05.05h.2q.25.1.5.15H17.85q2.05.5 4.15.95 30.8 7 61.4 7 30.75 0 61.3-7 2.05-.45 4.1-.95H149.1q.2-.05.4-.15h.2l-.05-.2v-.1l-.8-3.2m-11.6-72.75q-3.7 2.55-7.6 4.75-10 5.55-21.1 8.45-7.8 2-16.1 2.75-.5 0-1.05.05l-1.1.05q-1.45.1-2.9.15-1.55.05-3.1.05h-1.7q-3.1 0-6.1-.2l-1.1-.05q-3.9-.3-7.65-.85-4.8-.75-9.4-1.95Q47.7 15.1 38.05 9.9q-4.35-2.4-8.5-5.25L28 3.5q-1.45-1.05-2.8-2.1L23.35 0q13.7 23.4 37.4 34 5.4 2.4 10.6-1.55.15-.15.35-.25l.2-.2q.2-.15.4-.35l9.8-8.75q1.4-1.1 3 .25l9.4 8.5q.2.2.4.35l.2.2q.2.1.35.25 5.2 3.95 10.6 1.55 23.7-10.6 37.4-34l-1.85 1.4q-.6.5-1.25.95-.75.6-1.55 1.15l-1.55 1.15m26.85 42.3q-.3-.1-.5-.25-10.75-6.05-20.9 1.25-.55.4-1.1.85l1.65 8.5.7.15q1.65-1.85 3.75-2.95.25-.15.5-.25 3.55-1.7 8.35-1.5l2.05.2q.15 0 .3.05 1.3.2 2.5.65.05 0 .05.05.75.25 1.45.6 1.65.8 3.05 2.05 2.45-6.7-1.85-9.35v-.05z"
                    id="ShiSpe029__Symbol_388_0_Layer0_0_FILL"
                />
                <path
                    d="M248.6 264.25q-.05-.2-.25-.3-.15-.15-.35-.1-.107.027-.2.1-.08.057-.15.15-.1.15-.05.35v.05l4.2 21.4q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.2.25v-.05q.05-.2-.05-.35-.15-.2-.35-.25-.2-.05-.35.1-.2.1-.25.3l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.2-21.4z"
                    id="ShiSpe029__Layer3_0_FILL"
                />
                <path
                    fill="#FD8A17"
                    d="M188.9 263.65q-.75.75-1.1 1.65-.4.9-.4 1.95 0 2.1 1.5 3.6 1.5 1.45 3.5 1.5h.1q1.7 0 3-.95l.1-.1q.25-.2.5-.45 1.15-1.15 1.4-2.6.1-.5.1-1 0-2.05-1.5-3.6-.25-.25-.5-.45-.05-.05-.1-.05-1-.75-2.2-.95-.4-.05-.8-.05h-.1q-.2 0-.35.05-1.8.15-3.15 1.45z"
                    id="ShiSpe029__Layer3_1_FILL"
                />
                <path
                    id="ShiSpe029__Layer11_0_1_STROKES"
                    stroke="red"
                    strokeWidth={0.05}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                    fill="none"
                    d="M132.45 228.6l31.25 27.6 26.55-5.5"
                />
            </defs>
            <use xlinkHref="#ShiSpe029__Layer11_0_1_STROKES" />
            <use
                xlinkHref="#ShiSpe029__Symbol_389_0_Layer0_0_FILL"
                transform="translate(111.85 228.6)"
                id="ShiSpe029__col1n"
            />
            <use
                xlinkHref="#ShiSpe029__Symbol_388_0_Layer0_0_FILL"
                transform="translate(109.1 228.6)"
                id="ShiSpe029__col2n"
            />
            <use xlinkHref="#ShiSpe029__Layer3_0_FILL" />
            <use xlinkHref="#ShiSpe029__Layer3_1_FILL" />
        </g></g>
    )
}
export default ShiSpe029
