import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFC400",
    "col1s": "#BD7B00",
    "col2n": "#C40000"
}
function ShiSpe050({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <linearGradient
                    id="ShiSpe050__Gradient_1"
                    gradientUnits="userSpaceOnUse"
                    x1={226.875}
                    y1={263.012}
                    x2={264.125}
                    y2={239.188}
                    spreadMethod="pad"
                >
                    <stop offset="36.078%" stopColor="#323232" />
                    <stop offset="82.745%" stopColor="#929292" />
                    <stop offset="100%" stopColor="#2B2B2B" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_2"
                    gradientUnits="userSpaceOnUse"
                    x1={122.825}
                    y1={302.438}
                    x2={177.975}
                    y2={290.462}
                    spreadMethod="pad"
                >
                    <stop offset="28.627%" stopColor="#F9DA60" />
                    <stop offset="67.843%" stopColor="#FEA005" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_3"
                    gradientUnits="userSpaceOnUse"
                    x1={253.8}
                    y1={283.225}
                    x2={276.7}
                    y2={276.075}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="69.02%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_4"
                    gradientUnits="userSpaceOnUse"
                    x1={247.787}
                    y1={259.288}
                    x2={269.113}
                    y2={252.013}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="69.02%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_5"
                    gradientUnits="userSpaceOnUse"
                    x1={131.2}
                    y1={283.225}
                    x2={108.3}
                    y2={276.075}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="69.02%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_6"
                    gradientUnits="userSpaceOnUse"
                    x1={137.213}
                    y1={259.288}
                    x2={115.887}
                    y2={252.013}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="69.02%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_7"
                    gradientUnits="userSpaceOnUse"
                    x1={190.85}
                    y1={288.212}
                    x2={190.85}
                    y2={310.288}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="57.255%" stopColor="#8A8A8A" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_8"
                    gradientUnits="userSpaceOnUse"
                    x1={194.15}
                    y1={288.212}
                    x2={194.15}
                    y2={310.288}
                    spreadMethod="pad"
                >
                    <stop offset="0%" />
                    <stop offset="57.255%" stopColor="#8A8A8A" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_9"
                    gradientUnits="userSpaceOnUse"
                    x1={125.475}
                    y1={308.15}
                    x2={253.225}
                    y2={308.15}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="50.98%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_10"
                    gradientUnits="userSpaceOnUse"
                    x1={259.525}
                    y1={308.15}
                    x2={131.775}
                    y2={308.15}
                    spreadMethod="pad"
                >
                    <stop offset="0%" stopColor="#3D3D3D" />
                    <stop offset="50.98%" stopColor="#D7D7D7" />
                    <stop offset="100%" stopColor="#353535" />
                </linearGradient>
                <linearGradient
                    id="ShiSpe050__Gradient_11"
                    gradientUnits="userSpaceOnUse"
                    x1={158.125}
                    y1={263.012}
                    x2={120.875}
                    y2={239.188}
                    spreadMethod="pad"
                >
                    <stop offset="36.078%" stopColor="#323232" />
                    <stop offset="82.745%" stopColor="#929292" />
                    <stop offset="100%" stopColor="#2B2B2B" />
                </linearGradient>
                <path
                    fill="#CCC"
                    d="M271.4 268.65q-.55-2.15-1.2-4.25-.65-2.15-1.35-4.2-.1-.25-.2-.45-.148-.002-.3.6-.104.61-4.85 2.55-4.746 1.976-14.95 4.85l.7 3.4q-.065.032-.15.05l1.65 5.9q10.2-4.05 20.75-5.1.4-.05.8-.05-.15-.6-.3-1.15-.05-.3-.15-.6-.2-.8-.45-1.55m-156.6-4.25q-.643 2.092-1.25 4.25-.195.77-.4 1.55-.081.3-.15.6l-.3 1.1q.382.067.75.1 10.578 1.046 20.85 5.25l1.7-6.1-.25-.05.7-3.5q-9.342-1.078-14.35-4.4-4.962-3.284-5.45-3.4-.153-.034-.35-.1-.065.263-.15.5-.695 2.07-1.35 4.2z"
                    id="ShiSpe050__Layer12_0_MEMBER_0_FILL"
                />
                <path
                    fill="#CCC"
                    d="M183 287.85h19l-3 9.95h3.15l4.5-13.5h-28.3l4.5 13.5H186l-3-9.95z"
                    id="ShiSpe050__Layer12_0_MEMBER_2_FILL"
                />
                <path
                    d="M270.2 264.4q-.65-2.15-1.35-4.2-.1-.25-.2-.45-.15 0-.35.05-.45.1-.9.25-9.555 2.376-18.85 7.7l.7 3.4q10.981-2.351 21.5-2.5h.65q-.55-2.15-1.2-4.25M117.6 260q-.463-.09-.95-.2-.153-.034-.35-.1-.065.263-.15.5-.695 2.07-1.35 4.2-.643 2.092-1.25 4.25.365.003.7 0 10.498.154 21.5 2.45l.7-3.5q-9.344-5.185-18.85-7.6z"
                    id="ShiSpe050__Layer12_0_MEMBER_3_FILL"
                />
                <path
                    d="M248.6 264.25q-.04-.171-1 .35l4.2 21.3q.05.2.2.35.2.1.4.05t.3-.2v-.05q.1-.05.1-.15v-.2l-4.2-21.45m-111.25.4q-.61-.303-.95-.4l-4.2 21.45q-.017.113 0 .2l.05.15q.031.025.05.05.1.15.3.2.2.05.4-.05.15-.15.2-.35l4.15-21.25z"
                    id="ShiSpe050__Layer12_0_MEMBER_4_FILL"
                />
                <path
                    fill="#CCC"
                    d="M254.65 231.55q.55-1.3.95-2.1l-1.6-2.15q-.7.65-1.45 1.3l-1.85 1.4q-1.35 1.05-2.8 2.1l-1.55 1.15q-.3.2-.65.45-.19.128-.4.25-.638.446-1.3.85l-18.65 14.55-.1-.1-17 10.05q-5.244 5.901 1.3 10.85.6-.2 1.35-.65.5-.3 1.6-.9.6-.35 1.45-.75 2.3-1.2 5.45-3.05 3.1-1.9 5.6-3.25 1.65-.95 3.35-2.05 4.85-3.15 9.6-7.2 6.4-5.45 10.75-11.4 4.35-6 5.6-8.6.2-.4.35-.75M139.3 233.7l-.65-.45-1.55-1.15q-1.45-1.05-2.8-2.1l-1.85-1.4-.05-.05q-.702-.62-1.45-1.3l-1.55 2.2q.4.8.95 2.1.15.35.35.75 1.25 2.6 5.6 8.6 4.35 5.95 10.75 11.4 4.75 4.05 9.6 7.2 1.7 1.1 3.35 2.05 2.5 1.35 5.6 3.25 3.15 1.85 5.45 3.05.85.4 1.45.75 1.1.6 1.6.9.75.45 1.35.65 7.987-5.048 1.3-11.1l-13.95-9.5-.05.3-20.45-14.2q-1.261-.786-2.5-1.65-.253-.133-.5-.3z"
                    id="ShiSpe050__Layer12_0_MEMBER_6_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M129.85 41.9l-1.5-6.15L85.9 24.9h-.05l-2.4-10.6v-.05l-.7-6.2L84.5.25 76.4 1.9H56.7L48.05 0l2.05 8.05-.75 6.2v.05l-2.4 10.6h-.05L4.45 35.75l-1.5 6.15q-.45 1.75-.85 3.55.2.3 1 1.4.8 1.05-2.1 2.85l-.95 3.6v.25L0 53.6h.2q.25.1.5.15H.85l3 .7h.2l.1.05 49-13.6-4.85-14.75h36.2L79.65 40.9l49 13.6.1-.05h.2l3-.7H132.1q.25-.05.5-.15h.2l-.05-.05v-.25l-.95-3.6q-2.75-1.8-2.45-2.85.25-1.1 1.35-1.4-.4-1.8-.85-3.55z"
                    id="ShiSpe050__Symbol_416_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1s}
                    d="M48.75 3.8L47.7 0 1.45 11.75q-.1.3-.2.65L.35 16 0 17.4l.1-.3L48.75 3.8m81.5 8.6q-.1-.35-.2-.65L83.8 0l-1.05 3.8 48.65 13.3.1.3-.35-1.4-.9-3.6z"
                    id="ShiSpe050__Symbol_417_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M43.35 24.1q-.15-.1-.3-.15-1.5-.85-3.05-1.7-.3-.2-.6-.35-.45-.25-.9-.45-1.1-.65-2.25-1.35-3.35-2-6.95-4.3-.1-.05-.15-.1l-1.9-1.3-.8-.6q-.45-.35-.85-.7-.25-.15-.45-.35-.2-.2-.45-.4-.35-.35-.75-.65-.05-.1-.15-.15l-.05-.05q-8.3-2.6-15.9-6.7Q3.85 2.6 0 0q.1.15.2.35L.25.4Q.3.55.4.75q.2.4.45.85.55 1 1.2 2.2.15.2.3.45Q4.2 7.4 7.7 10.5q3.55 3.1 7.7 6.35 4.25 3.2 5.9 4.25 1.6 1 2.5 1.6.9.55 1.95 1.2 1 .65 3 1.9 3.9 2.4 8.15 4.45l.7-6.15h5.75M106 .75q.1-.2.15-.35l.05-.05q.1-.2.2-.35-3.85 2.6-7.85 4.8-7.6 4.1-15.9 6.7l-.05.05q-.1.05-.15.15-.4.3-.75.65-.25.2-.45.4t-.45.35q-.4.35-.85.7l-.8.6-1.9 1.3q-.05.05-.15.1-3.6 2.3-6.95 4.3-1.15.7-2.25 1.35-.45.2-.9.45-.3.15-.6.35-1.55.85-3.05 1.7-.15.05-.3.15h5.75l.7 6.15q4.25-2.05 8.15-4.45 2-1.25 3.05-1.9 1-.7 2-1.25 1-.6 2.55-1.6 1.5-1 5.75-4.2 4.15-3.25 7.7-6.35 3.5-3.1 5.35-6.25.15-.25.3-.45.65-1.2 1.2-2.2.25-.45.45-.85z"
                    id="ShiSpe050__Symbol_415_0_Layer0_0_FILL"
                />
                <g id="ShiSpe050__Layer12_0_FILL">
                    <path
                        fill="#CCC"
                        d="M245.3 233.95q-.638.446-1.3.85l-6.95 5.5q3.658-2.735 6.45-4.85 2.74-2.072 4.65-3.55-.128.112-.25.2l-1.55 1.15q-.3.2-.65.45-.19.128-.4.25z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_1)"
                        d="M211.95 281.25v-.45l-1.9 1.25 1.9-.8z"
                    />
                    <path d="M174.4 282.05l-2.5-1 4.95 13.8-2.45 3.3L186.8 313h11.4l12.4-14.85-2.5-3.2 3.85-13.7-1.9.8-5.6-11.2H179.9l-5.4 11 .45.2h-.55m26.1-32.8l-1.1.05q-3.1.2-6.3.2h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-1.204-.09-2.4-.2l.25 8.6q.15.05.3.15 10.825 4.19 19.7 0 .15-.1.3-.15l.25-8.6-2.4.2z" />
                    <path
                        fill="url(#ShiSpe050__Gradient_2)"
                        d="M174.5 281.85l-.1.2h.55l-.45-.2z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_3)"
                        d="M272.3 271.95q-.15-.6-.3-1.15-.05-.3-.15-.6-.2-.8-.45-1.55-.55-2.15-1.2-4.25-.65-2.15-1.35-4.2-.1-.25-.2-.45-.148-.002-.3.6-.104.61-4.85 2.55-4.746 1.976-14.95 4.85l.7 3.4q-.065.032-.15.05l1.65 5.9q.309 7.12 1.55 8.15.691.618.9.7.07-.02.1-.05v-.05q1.61-1.655 3.6-2.75l.2-.1q3.65-1.85 8.7-1.65l1.85.2q.15 0 .3.05.2.05.35.1.1 0 .2.05 1.7.35 3.2 1.05 1.8.85 3.3 2.15v-.1q0-.2-.05-.4-.2-1.05-.4-2.05-.05-.45-.15-.9l-.2-.9v-.2q-.35-1.7-.75-3.4l-.3-1.4q-.05-.3-.15-.6-.1-.5-.25-1h.05l-.1-.5q-.2-.8-.4-1.55z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_4)"
                        d="M268.65 259.75l-.1-.4-.9-2.4q-1.75-4.8-3.8-9.25-.15-.3-.3-.55l-9.95 14.8-3.75 1.65q-.55.2-1 .45l-.6.2.15 3.6q.092-.053.15-.1 10.204-2.874 14.95-4.85 4.746-1.94 4.85-2.55.152-.602.3-.6z"
                    />
                    <path
                        fill="#666"
                        d="M135.75 271.1l.25.05-1.7 6.1-1.55 8q-.797.106-.75.2l-.05.05.3.05.5-.25-.15.7q-.116-.141-.2-.25-.48.153-.6.15l-.1-.1q.003.045-.05.05.115.106.2.2l-1.3 5.4-.05.15.2-.05 8.9 5.15-9 13.55-.4.1q-.028.013-.1 0 .001.009-.05 0 .538.134 1.05.25 15.902 3.602 31.75 5.35 14.848 1.638 29.65 1.65 14.856 0 29.75-1.65 15.803-1.748 31.65-5.35l1-.2q0-.048-.05-.05l-.1.05-8-16.05 7.7-2.7-1.3-5.6q.04-.04.05-.1-.209-.082-.9-.7-1.241-1.03-1.55-8.15l-1.65-5.9q.085-.018.15-.05l-.7-3.4q-.058.047-.15.1l-.15-3.6-.3.35v-.05l-34.85 16.5-1.1-.25h-.05v.45l-3.85 13.7 2.5 3.2L198.2 313h-11.4l-12.4-14.85 2.45-3.3-4.95-13.8-34.85-16.5v.05l-.25.05-.05 3.15q-.154-.087-.3-.2l-.7 3.5z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_5)"
                        d="M116.65 259.8q-.153-.034-.35-.1-.065.263-.15.5-.695 2.07-1.35 4.2-.643 2.092-1.25 4.25-.195.77-.4 1.55-.081.3-.15.6l-.3 1.1q-.208.813-.4 1.6-.066.254-.15.5h.05q-.096.499-.25 1-.032.3-.1.6-.162.698-.35 1.4-.344 1.68-.7 3.4-.007.088-.05.15-.067.486-.15.95-.091.448-.2.9-.168 1.017-.35 2.05-.05.2-.05.4v.1q1.516-1.314 3.3-2.15 1.505-.714 3.2-1.1.1-.004.2-.05.174-.017.35-.05.15-.05.3-.05l1.85-.25q5.037-.164 8.7 1.7.1.052.2.1 1.999 1.075 3.55 2.75.053-.005.05-.05l.1.1q.12.003.6-.15.084.109.2.25l.15-.7-.5.25-.3-.05.05-.05q-.047-.094.75-.2l1.55-8 1.7-6.1-.25-.05.7-3.5q-9.342-1.078-14.35-4.4-4.962-3.284-5.45-3.4z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_6)"
                        d="M116.3 259.7q.197.066.35.1.488.116 5.45 3.4 5.008 3.322 14.35 4.4.146.113.3.2l.05-3.15-.65-.6q-.45-.25-1-.45l-3.75-1.65-10-14.85q-.115.305-.25.6-2.06 4.453-3.85 9.25-.417 1.19-.85 2.4-.065.188-.15.35z"
                    />
                    <path
                        fill="#333"
                        d="M119.5 243.05l-.4.65 2.35 3.4h-.05l10 14.85 3.75 1.65q.55.2 1 .45l.65.6.25-.05v-.05l34.85 16.5 2.5 1 .1-.2 5.4-11h24.55l5.6 11.2 1.9-1.25h.05l1.1.25 34.85-16.5v.05l.3-.35.6-.2q.45-.25 1-.45l3.75-1.65 9.95-14.8q-.03-.09-.05-.2l2.4-3.25-.4-.65-9.9-13.6-30.25 19.9-3.6-4q.058-.054.15-.1l.05-.05q-.139.045-.3.1-.026.016-.1 0-1.949.649-4 1.15-4.6 1.2-9.4 1.95-2.6.4-5.25.65l-.25 8.6q-.15.05-.3.15-8.875 4.19-19.7 0-.15-.1-.3-.15l-.25-8.6q-2.652-.271-5.25-.7-4.808-.705-9.45-1.9-1.938-.508-3.9-1.1-.22-.071-.5-.15.061.021.1.05.075.064.15.1.121.13.25.2l-.7 4v.05l-.05.25-33.35-20.4-9.9 13.6z"
                    />
                </g>
                <g id="ShiSpe050__Layer12_0_MEMBER_1_FILL">
                    <path
                        fill="url(#ShiSpe050__Gradient_7)"
                        d="M203.75 287.85l-11.25-1.9v23.5h2.1l9.6-11.65H199l4.75-9.95z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_8)"
                        d="M192.5 285.95l-11.15 1.9 4.65 9.95h-5.2l9.6 11.65h2.1v-23.5z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_9)"
                        d="M210.5 286.45l-2.4 8.5 2.5 3.2L198.2 313h-5.7v4.6q30.6 0 61.4-7l1-.2q0-.048-.05-.05l-.1.05-8-16.05-36.25-7.9z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_10)"
                        d="M130.05 310.35q.538.134 1.05.25 30.8 6.976 61.4 7V313h-5.7l-12.4-14.85 2.45-3.3-2.5-6.9-34.75 8.75-9 13.55-.4.1q-.028.013-.1 0 .001.009-.05 0z"
                    />
                </g>
                <g id="ShiSpe050__Layer12_0_MEMBER_5_FILL">
                    <path
                        fill="url(#ShiSpe050__Gradient_1)"
                        d="M263.5 246.95l2.4-3.25-.4-.65-9.9-13.6-30.25 19.9-.1-.1-17 10.05q-5.244 5.901 1.3 10.85v.05l2.4 10.6h.05l1.1.25 34.85-16.5v.05l.3-.35.6-.2q.45-.25 1-.45l3.75-1.65 9.95-14.8q-.03-.09-.05-.2z"
                    />
                    <path
                        fill="url(#ShiSpe050__Gradient_11)"
                        d="M119.5 243.05l-.4.65 2.35 3.4h-.05l10 14.85 3.75 1.65q.55.2 1 .45l.65.6.25-.05v-.05l37.35 17.5.1-.2-1.45-.6v-.45l2.4-10.6v-.05q7.987-5.048 1.3-11.1l-13.95-9.5v.05l-.05.25-33.35-20.4-9.9 13.6z"
                    />
                </g>
            </defs>
            <use xlinkHref="#ShiSpe050__Layer12_0_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_0_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_1_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_2_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_3_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_4_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_5_FILL" />
            <use xlinkHref="#ShiSpe050__Layer12_0_MEMBER_6_FILL" />
            <use
                xlinkHref="#ShiSpe050__Symbol_416_0_Layer0_0_FILL"
                transform="translate(126.1 255.9)"
                id="ShiSpe050__col1n"
            />
            <use
                xlinkHref="#ShiSpe050__Symbol_417_0_Layer0_0_FILL"
                transform="translate(126.75 289.6)"
                id="ShiSpe050__col1s"
            />
            <use
                xlinkHref="#ShiSpe050__Symbol_415_0_Layer0_0_FILL"
                transform="translate(139.3 233.7)"
                id="ShiSpe050__col2n"
            />
        </g></g>
    )
}
export default ShiSpe050
