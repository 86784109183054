import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#00284D",
    "col1l": "#006AC9",
    "col1n": "#00498A",
    "col2d": "#FFE000",
    "col2l": "#FFFFA6",
    "col2n": "#FFFF00"
}
function ObjMor101({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#666"
                    fillOpacity={0.302}
                    d="M302 62.6q-2.2-15.55-33.75-22.25-31.6-6.8-74.2-.85-42.2 5.9-70.6 20.85 25.5-11 61.05-15.95 41.15-5.75 71.55-.05 30.4 5.65 32.35 19.4 1.9 13.7-25.8 27.45-27.7 13.9-68.85 19.65-41.15 5.75-71.6 0-3.25-.55-6.25-1.25 6.25 2.3 14.4 4.1 31.7 6.75 74.25.8 42.6-5.95 71.1-21.1 28.5-15.25 26.35-30.8m-39.65-30.2q3.25.55 6.25 1.25-6.25-2.35-14.4-4.1-31.7-6.7-74.25-.75-42.6 5.95-71.1 21.05-28.5 15.2-26.35 30.75 2.15 15.55 33.75 22.3 31.6 6.8 74.2.85 42.2-5.9 70.6-20.85-25.5 11-61.05 15.95-41.15 5.75-71.55.05-30.4-5.65-32.35-19.35Q94.2 65.8 121.9 52q27.65-13.8 68.8-19.55t71.65-.05z"
                    id="ObjMor101__Layer10_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M10.9 55.2q3.05.25 5.25 3.05 1.05 1.35 1.25 3.7.15 2.05-.2 3-.4.95-.9 1.6-.5.55-1.4 1.15-.95.65-2.85.8-2.55.15-3.95-1.6-1.75-2.3-1-4.45.45-1.4 1.55-2.2.5-.45 1.3-.55 1.45-.15 2.3.9 1.05 1.35.5 2.65-.4 1.4-.05 1.9.6.8 1.45.15 2.65-2.05-.3-5.85-1.65-2.15-4-1.9-1.5.15-2.55 1-1.45 1.15-2.2 2.7-1.35 3.15 1.25 6.45 2.35 3.05 5.85 2.9 2.45-.15 4.1-1 1.65-.9 2.45-2.9.85-1.95.55-5-.3-3-1.85-4.9-2.05-2.7-5.4-3.45-3.95-.85-6.95 1.5-2.5 1.95-3.9 4.6-2.8 5.4 1.05 10.3 3.6 4.6 9.15 5.1 4.3.35 6-1.05.15-.15.3-.4.15-.55-.2-1.05-.4-.45-1.9-.25-2.85.5-3.3.5-5.05.35-8.55-4-4.3-5.5 2.6-12 1.95-1.55 4.55-1.4m37.95-43.95q.35-3.45-3.2-5.75-3.25-2.05-6.5-.8-2.25.9-3.55 2.25-1.25 1.35-1.35 3.5-.25 2.1 1.05 4.9 1.25 2.8 3.35 4.1 2.75 1.85 6.25 1.5 3.95-.45 6.05-3.65 1.7-2.65 2.25-5.6.85-6-4.35-9.4Q44-.95 38.55.4 34.4 1.45 33.2 3.3q-.1.2-.15.5.05.6.55.95.55.3 1.85-.4 2.6-1.4 3-1.55 4.7-2 9.35 1.05 5.85 3.85 1.4 12.2-1.35 2.1-3.85 2.8-2.95.75-5.95-1.2-1.4-.95-2.35-3.15-.85-1.85-.75-2.9.05-1 .3-1.8.3-.7.95-1.55.65-.85 2.45-1.6 2.35-.95 4.25.2 2.4 1.6 2.4 3.9 0 1.5-.75 2.6-.4.55-1.1.95-1.3.6-2.5-.2-1.4-.95-1.3-2.3-.05-1.5-.55-1.8-.85-.55-1.4.3-1.85 2.85 2.15 5.45 2.25 1.45 4.45.5 1.3-.65 2.05-1.75 1-1.55 1.15-3.25m144.35-7q2.45 1.65 2.75 1.95 4 3.2 3.6 8.75-.5 6.9-9.9 7.05-2.5-.15-4.3-2-2.1-2.2-1.85-5.8.15-1.65 1.55-3.6 1.3-1.6 2.2-2 .9-.45 1.7-.55.8-.15 1.85 0 1.1.2 2.55 1.35 2.05 1.6 1.9 3.8-.2 2.85-2.25 3.95-1.25.75-2.6.65-.65-.05-1.35-.5-1.15-.85-1.05-2.25.1-1.7 1.35-2.3 1.25-.7 1.3-1.25.05-1.05-1-1.15-3.35-.25-3.65 4.5-.2 2.7 1.7 4.1 1.2.9 2.55 1 1.85.1 3.4-.55 3.15-1.45 3.45-5.65.3-3.75-2.4-6-1.9-1.6-3.75-2.05-1.75-.45-3.75.45-1.9.9-3.7 3.4-1.85 2.4-2 4.85-.25 3.35 1.75 6.25 2.3 3.25 6.1 3.5 3.15.2 6.05-.8 5.65-2.1 6.1-8.3.4-5.85-3.4-10-2.9-3.05-5.05-3.2-.25 0-.55.1-.5.3-.55.9-.05.6 1.25 1.4z"
                    id="ObjMor101__Symbol_251_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1l}
                    d="M15.8 11.25q.35-3.45-3.2-5.75-3.25-2.05-6.5-.8-2.25.9-3.55 2.25Q1.3 8.3 1.2 10.45q-.25 2.1 1.05 4.9 1.25 2.8 3.35 4.1 2.75 1.85 6.25 1.5 3.95-.45 6.05-3.65 1.7-2.65 2.25-5.6.85-6-4.35-9.4Q10.95-.95 5.5.4 1.35 1.45.15 3.3q-.1.2-.15.5.05.6.55.95.55.3 1.85-.4 2.6-1.4 3-1.55 4.7-2 9.35 1.05 5.85 3.85 1.4 12.2-1.35 2.1-3.85 2.8-2.95.75-5.95-1.2Q4.95 16.7 4 14.5q-.85-1.85-.75-2.9.05-1 .3-1.8.3-.7.95-1.55.65-.85 2.45-1.6 2.35-.95 4.25.2 2.4 1.6 2.4 3.9 0 1.5-.75 2.6-.4.55-1.1.95-1.3.6-2.5-.2-1.4-.95-1.3-2.3-.05-1.5-.55-1.8-.85-.55-1.4.3-1.85 2.85 2.15 5.45 2.25 1.45 4.45.5 1.3-.65 2.05-1.75 1-1.55 1.15-3.25z"
                    id="ObjMor101__Symbol_252_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M10.9 2.1q3.05.25 5.25 3.05 1.05 1.35 1.25 3.7.15 2.05-.2 3-.4.95-.9 1.6-.5.55-1.4 1.15-.95.65-2.85.8-2.55.15-3.95-1.6-1.75-2.3-1-4.45.45-1.4 1.55-2.2.5-.45 1.3-.55 1.45-.15 2.3.9 1.05 1.35.5 2.65-.4 1.4-.05 1.9.6.8 1.45.15 2.65-2.05-.3-5.85-1.65-2.15-4-1.9-1.5.15-2.55 1-1.45 1.15-2.2 2.7-1.35 3.15 1.25 6.45 2.35 3.05 5.85 2.9 2.45-.15 4.1-1 1.65-.9 2.45-2.9.85-1.95.55-5-.3-3-1.85-4.9Q15.4 1 12.05.25 8.1-.6 5.1 1.75 2.6 3.7 1.2 6.35q-2.8 5.4 1.05 10.3 3.6 4.6 9.15 5.1 4.3.35 6-1.05.15-.15.3-.4.15-.55-.2-1.05-.4-.45-1.9-.25-2.85.5-3.3.5-5.05.35-8.55-4-4.3-5.5 2.6-12Q8.3 1.95 10.9 2.1z"
                    id="ObjMor101__Symbol_253_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M54.4 70.6l-4.05-1.7 2.1 3.85-2.9 3.25 4.35-.75L56.2 79l.55-4.3 4.3-1-4-1.9.35-4.35-3 3.15m-40.85-8.9l.3-8.1-5.35 6.2-7.6-2.9 4.15 6.95L0 70.3l7.9-1.8 4.55 6.7.7-8.05L21 64.9l-7.45-3.2M56.3 32.1l-12.8 3.1-7.45-10.8-.9 13.05L22.6 41.2l12.05 5-.35 13.15 8.5-10 12.35 4.4-6.85-11.2 8-10.45m87.25 49.55l-15.6-1.85 10.9 11.25-6.6 14.3 14.1-7 11.55 10.7-2.25-15.55 13.75-7.75-15.55-2.65-3.1-15.4-7.2 13.95m-26.1 1.2l-8.1-.05-2.85-7.6-2.55 7.8-8.15.3 6.5 4.8-2.1 7.9 6.6-4.7 6.8 4.35-2.5-7.65 6.35-5.15M85.2 34.8l3.05-3.15-4.4.55-2.1-3.75-.8 4.25-4.3.8 3.9 2.05-.65 4.3 3.25-3 3.95 1.85-1.9-3.9m50.85-23.35l-7.35-3.4.45-8.05-5.55 6.05-7.45-3 3.95 7-5.2 6.3 7.95-1.6 4.4 6.8.9-8.05 7.9-2.05M215 70.3l4.4-6.85 7.95 1.6-5.15-6.25 4.05-7.1-7.6 2.95-5.45-6 .5 8.15-7.4 3.25 7.8 2.1.9 8.15m-45.1-36.55l-4.05-12.5 10.25-8.3-13.2.05L158.15.75l-3.9 12.5-13.1.75 10.6 7.65-3.4 12.7 10.6-7.75 10.95 7.15m65.35-4.1l.35-4.35-3 3.15-4.05-1.7 2.1 3.85-2.9 3.25 4.35-.75 2.3 3.75.55-4.3 4.3-1-4-1.9m-27.15-14l4.4.4-3.2-3.05 1.65-4.05L207.2 11l-3.35-2.9.75 4.25-3.7 2.3 4.35.6 1 4.3 1.85-3.9z"
                    id="ObjMor101__Symbol_254_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M7.85 67.45l-3 3.15L.8 68.9l2.1 3.85L0 76l4.35-.75L6.65 79l.55-4.3 4.3-1-4-1.9.35-4.35m71.3-59.4L79.6 0l-5.55 6.05-7.45-3 3.95 7-5.2 6.3 7.95-1.6 4.4 6.8.9-8.05 7.9-2.05-7.35-3.4m86.3 62.25l4.4-6.85 7.95 1.6-5.15-6.25 4.05-7.1-7.6 2.95-5.45-6 .5 8.15-7.4 3.25 7.8 2.1.9 8.15z"
                    id="ObjMor101__Symbol_255_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M33.7 7.7l-12.8 3.1L13.45 0l-.9 13.05L0 16.8l12.05 5-.35 13.15 8.5-10 12.35 4.4-6.85-11.2 8-10.45m54.8 55.9l6.35-5.15-8.1-.05-2.85-7.6-2.55 7.8-8.15.3 6.5 4.8-2.1 7.9 6.6-4.7 6.8 4.35-2.5-7.65M212.65 5.25L213 .9l-3 3.15-4.05-1.7 2.1 3.85-2.9 3.25 4.35-.75 2.3 3.75.55-4.3 4.3-1-4-1.9z"
                    id="ObjMor101__Symbol_256_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#ObjMor101__Layer10_0_FILL" />
            <use
                xlinkHref="#ObjMor101__Symbol_251_0_Layer0_0_FILL"
                transform="translate(90.2 30.8)"
                id="ObjMor101__col1n"
            />
            <use
                xlinkHref="#ObjMor101__Symbol_252_0_Layer0_0_FILL"
                transform="translate(123.25 30.8)"
                id="ObjMor101__col1l"
            />
            <use
                xlinkHref="#ObjMor101__Symbol_253_0_Layer0_0_FILL"
                transform="translate(90.2 83.9)"
                id="ObjMor101__col1d"
            />
            <use
                xlinkHref="#ObjMor101__Symbol_254_0_Layer0_0_FILL"
                transform="translate(66.95 15.1)"
                id="ObjMor101__col2n"
            />
            <use
                xlinkHref="#ObjMor101__Symbol_255_0_Layer0_0_FILL"
                transform="translate(116.5 15.1)"
                id="ObjMor101__col2l"
            />
            <use
                xlinkHref="#ObjMor101__Symbol_256_0_Layer0_0_FILL"
                transform="translate(89.55 39.5)"
                id="ObjMor101__col2d"
            />
        </g></g>
    )
}
export default ObjMor101
