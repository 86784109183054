import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function FacNos015({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M25.7 22.95q-1.01-.783-1.8-1-.797-.156-1 0-.25.15-.3.4-.1.25.05.5.152.204.65.25.493.039 1.15.45.656.41.65 1.05.048.646-.55 1.25-1 .9-3.15.9-.45 0-1.4-.35-1.25-.5-1.4-.45-.297-.046-5.15 2.7-4.858 2.792-6.05 2.9-1.19.149-1.45.2-.209.064-1.5-.2-1.243-.284-1.75-.6-1-.7-1.2-1.15-.05-.05-.05-.1-.15-.3-.15-1.65.05-3.3 6.15-14Q9.45 10.4 15.2 1q.15-.25.1-.5-.1-.25-.3-.4-.25-.15-.5-.05-.25.05-.4.25-5.75 9.4-7.8 13.1Q-.05 24.55 0 28.05q0 1.8.25 2.25.25.75 1.7 1.7.437.336 1.45.65 3.064.846 5 0 2.167-.704 5.35-2.6 4.65-2.75 4.85-2.8.05.05.95.35v.05q1.3.4 1.85.4 2.75 0 4.05-1.25v-.05q.997-.994 1.1-2 .16-1.017-.85-1.8z"
                    id="FacNos015__Symbol_27_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos015__Symbol_27_0_Layer0_0_FILL"
                transform="translate(174.7 180.4)"
                id="FacNos015__col1n"
            />
        </g></g>
    )
}
export default FacNos015
