import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function FacNos016({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M19.9 1.05q.15-.2.1-.45-.05-.3-.25-.45-.2-.2-.45-.15-.3.05-.45.25-1.15 1.45-1.65 1.6-.5.05-.7-.05-.45-.3-1.15-.3-.75-.2-1.95.9-1.3 1.1-2.35 1.6-1.05.45-3.55-.65Q5.4 1.3 4.65 1.5q-.7 0-1.1.3-.25.1-.7.05Q2.3 1.7 1.2.25 1 .05.75 0q-.3-.05-.5.15Q.05.3 0 .6q-.05.25.15.45Q1.7 3.1 2.7 3.15q1 .05 1.5-.2V2.9q.2-.1.5-.1h.05q.3.05 1.35 1l1.4 1.05.3.2q2.2.9 4.4 0 .5-.3 1.2-.85.3-.25.55-.4 1-.95 1.3-1h.05q.3 0 .5.1l.05.05q.45.25 1.45.2h.05q.95-.05 2.55-2.1z"
                    id="FacNos016__Symbol_28_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos016__Symbol_28_0_Layer0_0_FILL"
                transform="translate(182.5 201.95)"
                id="FacNos016__col1n"
            />
        </g></g>
    )
}
export default FacNos016
