import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#E4B670",
    "col2l": "#D19532",
    "col2n": "#A37831",
    "col2s": "#43341A"
}
function PetPet029({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M42.3 47.25q3.65 1.5 7.15 0 1.15-.55 1.45-.65.15-.05.4-.15.1-.05.25-.05.25-.05.45-.1 2.2-.5 4.15.7l.2.1q4.6 2.85 2.45-2.15h-.1q-.25-.45-.55-.9-.35-.55-.75-1.05-.9-1.2-2-2.15Q38.5 30.1 21.25 32.8v-.05q-3.5-1.6-3.15-8 0-.2.05-.3-.05-.05 0-.1-.05-.05 0-.1v-.3q.6-.8 1-1.55.1-.35.2-.65.55-1.15.55-2.45 0-2.55-2-4.35.15-3.25 3.25-4.35 1.6-.25 2.75-1.5 1.35-1.65 1.35-3.8 0-2.2-1.35-3.75Q22.5 0 20.4 0 18.45 0 17 1.55 15.6 3.1 15.6 5.3q0 2 1.2 3.6 1.1 3.4-1.9 4.3-1.05-.25-2.2-.25-1.15 0-2.2.25-3.1-.85-2.05-4.3 1.2-1.6 1.2-3.6 0-2.2-1.4-3.75Q6.85 0 4.85 0 2.8 0 1.4 1.55 0 3.1 0 5.3q0 2.15 1.4 3.8 1.15 1.25 2.75 1.5 3.1 1.15 3.25 4.4-1.9 1.8-1.9 4.3 0 1.7.85 3.1.4.8 1.05 1.65.45.55.8 1.2.05.1.05.2.45 1.1.45 2.45v.4h-.05v.55q-.05.05-.05.2h.05q-.05.2-.05.4-.15 1.1-.6 2.65-1.7 12.2 7.55 15.25 3.15 1 5.3 0l.2-.1q1.25-.55 1.5-.65.05-.05.35-.15.15-.05.3-.05h.05l.2-.05q2.25-.55 4.4.65.25.2.55.35 3.2 1.35 6.65 0l.2-.1q1.25-.55 1.45-.65l.45-.15q.15-.05.25-.05h.15q.1-.05.25-.1 1.8-.4 3.35.3.2.05.5.2.3.25.65.45m-1.45-1.45q.05 0 .05.05h-.05v-.05m-15.05 0v.05h-.05l.05-.05z"
                    id="PetPet029__Symbol_101_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M39.45 7.8q-.5-.8-1.05-1.5-2.95-3.15-6.75-4.7Q27.5-.15 23.35 0 7.9-.05 2.25 13.75q-1 2.3-1.6 5-.25 1.1-.4 2.35-.05.15-.05.35-.15.65-.2 1.4 0 .75.1 1.35 0 .05.05.15.05.55.2 1.05 1.35 4.8 8.35 4.9 1.85-.15 2.65-.2.65-.05.8-.05l.05.05q.6.05 1.35.25 1.95 2.1 4.5 3.15 1.35.55 2.75.75 0-.05.05 0v.05q.05.05.15.05 3.25.35 5.9.1 3.2-.25 5.6-1.3l.7-.35q.45-.2 1.05-.5 1.65-1.15 4.15-3.8 1.25-1.3 2.05-2.9 1.1-2.15 1.6-4.2 1.35-7.7-2.6-13.6z"
                    id="PetPet029__Symbol_102_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M33.1 9.3q.2.1.4.3 5.55 5.7 2.15 14.2-.95 1.75-2.65 3.3-2.25 2-5.35 2.9-7.3 1.5-11.95-2.5-4.75-4.9-1.95-10.9 2.6-4.35 7.9-4.65 1.3 0 2.75.3 3.55.8 4.65 4.35.1.45.1 1.05v.5q-.35 1.5-1.7 1.5-.4-.2-.75-.2l-.2-.2h-.05v-.1-.25q-.05-.55-.5-1.15-.55-.95-1.25-.95-.15 0-.25.05-.4-.1-.75-.1-2.45-.1-3.95 1.15-2.2 2.4-1.15 5 1.25 2.9 5.35 3.25H24q3.6 0 5.9-1.9h-.05q.45-.35.85-.8 2.75-3.8 1.55-7.95-.8-2.1-2.5-3.65-1.6-1.35-3.6-2-4.05-.8-7-.3-5.65 1.05-8.45 5.95l-.1.4q-.95 2.45-1.1 4.4-.3 3.3.9 6.1.6 1.45 1.55 2.8.2.3.45.6.3.25.55.55 1.95 2.1 4.5 3.15 1.35.55 2.75.75 0-.05.05 0v.05q.05.05.15.05 3.25.35 5.9.1 3.2-.25 5.6-1.3l.7-.35q.45-.2 1.05-.5 1.65-1.15 4.15-3.8 1.25-1.3 2.05-2.9 1.1-2.15 1.6-4.2 1.35-7.7-2.6-13.6-.5-.8-1.05-1.5-2.95-3.15-6.75-4.7Q26.9-.15 22.75 0 7.3-.05 1.65 13.75q-1 2.3-1.6 5L0 19.85Q0 21 1 21.9q1 .9 2.25.9 1.5 0 2.25-2.15.05-.1.05-.2.3-2.05.7-3.65.1-.5.25-1 2.05-7.65 9.05-9.85h.05q9.6-3.1 16.35 2.3l.55.45q.25.3.6.6M22.45 23.4h-.15q.05-.05.1-.05 0 .048.05.05z"
                    id="PetPet029__Symbol_103_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2s}
                    d="M21.95 2.9v.05q-1.1-1-2.45-1.7-.65-.3-1.4-.5-7-2.1-13.1 1.85-.6.45-1.15.9-1.8 1.7-2.8 4.35-.8 1.85-1 4.05-.4 4.95 2.4 8.9.35.5.75.95l.05.05q.6.05 1.35.25-.25-.3-.55-.55-.25-.3-.45-.6-.95-1.35-1.55-2.8-1.2-2.8-.9-6.1.15-1.95 1.1-4.4l.1-.4q2.8-4.9 8.45-5.95 2.95-.5 7 .3 2 .65 3.6 2 1.7 1.55 2.5 3.65 1.2 4.15-1.55 7.95-.4.45-.85.8h.05q-2.3 1.9-5.9 1.9h-.05q-4.1-.35-5.35-3.25-1.05-2.6 1.15-5 1.5-1.25 3.95-1.15.35 0 .75.1.1-.05.25-.05.7 0 1.25.95.45.6.5 1.15v.35h.05q.1-.05.15-.1.1-.1.1-.3 0-2.85-3-2.95-2.9-.1-4.55 1.35l-.5.5q-2.2 2.65-1 5.5 1.3 3.25 6 3.75h.3q.65 0 1.25-.05 3.05-.3 5.15-2 .6-.55 1.05-1.05 3.05-3.65 1.75-8.3-.75-2.6-2.9-4.4z"
                    id="PetPet029__Symbol_104_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M78.2 332.6q-1-1.1-2.5-1.1-1.45 0-2.5 1.1-1 1.1-1 2.7 0 1.55 1 2.7 1.05 1.1 2.5 1.1 1.5 0 2.5-1.1 1-1.15 1-2.7 0-1.6-1-2.7m-21.95 2.7q0 1.55 1 2.7 1.05 1.1 2.5 1.1t2.5-1.1q1.05-1.15 1.05-2.7 0-1.6-1.05-2.7t-2.5-1.1q-1.45 0-2.5 1.1-1 1.1-1 2.7z"
                    id="PetPet029__Layer1_0_FILL"
                />
                <path
                    d="M71.4 355.4q.45-.5.75-1.1-1 .75-2.2 1.05-.85.25-1.85.25h-.3q-1.8 0-3.25-.6.4.45 1.1.9 1.15.7 2.95.6 1.8-.1 2.8-1.1m4.85-18.1q.05-.2.05-.35 0-.5-.3-.85-.35-.3-.8-.3-.35 0-.6.15-.1.05-.2.15-.3.35-.3.85 0 .45.3.75.3.35.8.35.45 0 .8-.35.15-.15.25-.4m-16.75-1.2q-.3.35-.3.85 0 .15.05.35.1.25.25.4.3.35.8.35t.75-.35q.35-.3.35-.75 0-.5-.35-.85l-.15-.15q-.25-.15-.6-.15-.5 0-.8.3z"
                    id="PetPet029__Layer1_0_MEMBER_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PetPet029__Symbol_101_0_Layer0_0_FILL"
                transform="translate(55.1 329.9)"
                id="PetPet029__col1n"
            />
            <use
                xlinkHref="#PetPet029__Symbol_102_0_Layer0_0_FILL"
                transform="translate(76.25 338.45)"
                id="PetPet029__col2n"
            />
            <use
                xlinkHref="#PetPet029__Symbol_103_0_Layer0_0_FILL"
                transform="translate(76.85 338.45)"
                id="PetPet029__col2l"
            />
            <use
                xlinkHref="#PetPet029__Symbol_104_0_Layer0_0_FILL"
                transform="translate(85.2 346.75)"
                id="PetPet029__col2s"
            />
            <use xlinkHref="#PetPet029__Layer1_0_FILL" />
            <use xlinkHref="#PetPet029__Layer1_0_MEMBER_0_FILL" />
        </g></g>
    )
}
export default PetPet029
