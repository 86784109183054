import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function FacNos014({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M22.55 6l.05.05Q19.45 3.85 17.95.4q-.1-.25-.35-.35-.25-.1-.5 0t-.35.35q-.1.25 0 .5 1.6 3.75 5.1 6.2 1.35.95 1.35 3.1 0 1.65-1.25 2.85-.85.75-3.4 2.65h-.05q-1.4 1.15-2.2 1.75-.4.25-.65.45-1.496.81-2.9 1.1-.3.05-.45.1-.25-.05-.45-.05V19q-1.55-.3-3.05-1.15l.05.05q-.25-.2-.6-.45l-.05-.05q-.85-.6-2.15-1.7H6q-2.6-1.9-3.45-2.65-1.25-1.2-1.25-2.85 0-2.15 1.35-3.1h.05Q6.35 4.55 7.85 1q.1-.25 0-.5T7.5.15q-.25-.1-.5 0T6.65.5q-1.4 3.25-4.7 5.55V6Q0 7.25 0 10.2q0 2.25 1.65 3.8.85.8 3.6 2.75 1.319 1.162 2.25 1.75.3.2.55.4l.1.1q1.75 1 3.45 1.3.25.05.55.1h.25q.2-.05.55-.1H13q1.6-.3 3.4-1.3 0-.05.05-.1.25-.2.6-.4h.05q.8-.65 2.25-1.8l-.05.05Q22 14.8 22.85 14q1.65-1.55 1.65-3.8 0-2.95-1.95-4.2z"
                    id="FacNos014__Symbol_26_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos014__Symbol_26_0_Layer0_0_FILL"
                transform="translate(180.25 191.1)"
                id="FacNos014__col1n"
            />
        </g></g>
    )
}
export default FacNos014
