import { AssetSvgProps } from "../../../shared/assets"
function PlaOut063({ className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#666"
                    d="M385 385V0H0v385h385z"
                    id="PlaOut063__Layer3_0_FILL"
                />
                <path
                    fill="#726359"
                    d="M53.05 197.5v-23.85L21.3 185.7q-6.438 36.318-.05 72.55v.8l6.2-5V254q3.963-5.188-.05-10.4h.05-.5l5.4-4.05.05-.05q5.792-5.32 0-9.7l5.9-4.05.05-.05q3.851-4.837 0-9.7l6.55-4.05q4.054-5.175 0-10.35h.35l7.8-4.1m279-23.8l-.1-.05v23.85l7.8 4.1h.35q-4.022 5.175 0 10.35l6.55 4.05q-4.936 4.875 0 9.75l5.95 4.05q-4.304 4.875 0 9.75l5.45 4.05h-.5q-4.254 5.2 0 10.4v.1l6.2 4.95v-.8q8.212-37.22-.05-72.5l-31.65-12.05z"
                    id="PlaOut063__Layer2_1_FILL"
                />
                <path
                    fill="#AD9B90"
                    d="M21.2 185.75H0v72.5h21.25l.05-72.55-.1.05M357.55 254v-10.4H27.4l.05 10.4h330.1m-17.45-52.4h-.35l.1.05H45.15l.1-.05h-.35v10.35h295.2V201.6m12.5 37.95v-9.75H32.4v9.7l-.05.05H352.6m-5.95-13.8V216H38.35v9.7l-.05.05h308.35m38.35-40h-21.3l.05 72.5H385v-72.5z"
                    id="PlaOut063__Layer2_2_FILL"
                />
                <path
                    fill="#FFA24D"
                    d="M368.6 118.35q.014-3.067 1.8-5.25 1.85-2.15 4.45-2.15 1.765 0 3.15 1 .419-1.947 1.85-3.45 2-2.05 4.85-2.05h.3V0H0v122.45q1.48.59 2.55 1.25.545.34.95.65.086.07.15.1.477-1.596 1.75-2.9 2.087-2.037 4.95-2.05.412-2.362 2.2-4.15 2.3-2.3 5.55-2.3t5.55 2.3q.87.87 1.4 1.85.065.076.1.1.61-1.929 2.15-3.5 2.5-2.45 6-2.45t5.95 2.45q2.359 2.359 2.5 5.6h.35q2.95 0 5.05 2.2 1.432 1.534 1.9 3.45.45 1.098.45 2.35 0 1.095-.35 2 .636-.15 1.35-.15 1.438 0 2.55.7v67.55H75.3l13.45-8.6h17.75l71.15-34.2q66.083 5.605 100.85 34.2h17.75l13.45 8.6h22.25v-75.05q.322-.212.75-.4 1.25-.55 2.9-.55 2.65 0 4.1 1.4.441.413.75.95.9 1.397 1.15 3.65.062-.06.1-.15.388-.538.9-1.05 1.75-1.75 4.2-1.75t4.2 1.75q1.586 1.586 1.7 3.75 1.098-.899 2.4-1.15.54-.1 1.1-.1 1.077 0 2 .35.417-1.583 1.65-2.85.913-.89 2-1.35 0-2.75 1.9-4.7 1.95-1.9 4.7-1.9h.15z"
                    id="PlaOut063__Layer2_3_FILL"
                />
                <path
                    fill="#CB764C"
                    d="M226.55 30.35v-.05h-68.1v.05l-51.95.1V188.9h5.65l5.7-4.65h149.3l5.7 4.65h5.65V30.45l-51.95-.1z"
                    id="PlaOut063__Layer2_4_FILL"
                />
                <path
                    fill="#5A7B85"
                    d="M126.1 161.75l.05 5.8h32.4L178.4 125h-35.15l-17.15 36.75m3.4-36.75l-3.55 7.6.1 15.35 10.7-22.95h-7.25m129.6-8.75l.05-14.25-6.65 14.25h6.6m-30.5 51.3h6.9L255.35 125h-6.9l-19.85 42.55m-47.25-20.1V132.6l-16.3 34.95h6.9l9.4-20.1m60.6-22.45H207l-3.35 7.2v35.35h18.45L241.95 125m-70.4-75.6h-6.5l-31.2 66.85h6.5l31.2-66.85m9.8 0h-2.8l-31.2 66.85h34V49.4m53.45-.05l-.05.05h-6.5l-24.6 52.75v13.95l-.05.1h.05v.05h.15l31.1-66.9h-.1m24.4 38.6l.2-38.55h-17.3l-31.05 66.85H246l13.2-28.3z"
                    id="PlaOut063__Layer1_2_FILL"
                />
                <path
                    fill="#562E1B"
                    d="M193.75 41.45h-1.15v142.8h1.15V41.45z"
                    id="PlaOut063__Layer1_3_FILL"
                />
                <g id="PlaOut063__Layer3_1_FILL">
                    <path
                        fill="#94D243"
                        d="M0 276.1l21.25-17.05v-.8q-8.872-22.788-21.25 0v17.85m363.75-17.85v.8L385 276.1v-17.85q-17.27-24.685-21.25 0z"
                    />
                    <path
                        fill="#639325"
                        d="M56.85 148.45v-29.5l-21.8-21.2L0 122.45v56.45h4v-.1q0-1.218.95-2.7.38.05.8.05 2.05 0 5.5-6.55 3.45-7.3 4.25-8.85.996-1.892 1.9-3.45l39.45-8.85M6.35 130.5q.047-.169.05-.35.1.065.15.1-.01.058-.05.1l-.15.15m9.4 7.15q.04-.137.05-.3.544.444 1 .85-.2.079-.55.15-.275.131-.65.25v-.5q.084-.218.15-.45m362.45 7.75q.215-.138.45-.3 1.9-1 4.25-1 1.17 0 2.1.1v-37.75L362.85 94.6l-38.5 21.35v27.2l18.75 6.75q-4.385 3.668-7.55 7.4-3.3 3.85-3.3 5.45 0 .05.25 1.85.6 1.8 2.35 1.8 1.1 0 10.7-5.6 8.228-4.773 10.7-6.15.754-.388 1-.5 9.85-4.3 12.65-5.65 5.37-2.604 7.85-4.35.306.617.45 1.25m-8.35-23.9v.1l-.05-.1h.05m-7.4 4.5q.15.046.3.1v.15q-.154-.092-.3-.25z"
                    />
                    <path
                        fill="#666"
                        d="M362.75 126.1q-.15-.054-.3-.1.146.158.3.25v-.15m7.1-4.5v-.1h-.05l.05.1z"
                    />
                </g>
                <g id="PlaOut063__Layer3_1_MEMBER_0_FILL">
                    <path
                        fill="#78B628"
                        d="M43 137.65h-.45q-1.234.077-2.3.5-.486-1.583-1.85-2.85-2.1-1.9-5.1-1.9-3 0-5.1 1.9-2.1 1.95-2.1 4.7 0 1.356.5 2.5h-.5q-1.254 0-2.35.35-1.549.528-2.8 1.75-.748.766-1.25 1.65-1.1 1.748-1.1 3.95v.25q-2.96.156-5.1 2.25-.754.77-1.25 1.65-.871 1.356-.9 2.55-.1.664-.1 1.35 0 1.723.6 3.15-1.402.696-2.6 1.85-2 2-2 4.5 0 1.4.5 2.65.3.75 1.1 2.45-1.7.5-3.3 2.45Q4 177.25 4 178.8v.1l16.65 8.4q18.307 9.155 32.4-20.25 9.092-12.738 0-25.5-.779-.2-1.65-.2-1.176 0-2.2.4-.436-1.328-1.6-2.4-1.9-1.7-4.6-1.7m323.8 3.5q-.847.431-1.6 1.05-2.13 1.752-2.25 4.15-.466-.816-1.2-1.55-2-2-4.85-2-2.85 0-4.9 2-2 2.05-2 4.9v.15q-.026.118-.05.2-1.126-.35-2.4-.35-3.2 0-5.45 2.25t-2.25 5.45q0 2.11.95 3.8-2.283-.557-4.5.6-2.55 1.3-2.55 4 0 .967.2 1.9 12.444 22.342 33.7 11.2 5.165 12.285 17.35 0v-34.7q-.93-.1-2.1-.1-2.35 0-4.25 1-.235.162-.45.3-.412-1.812-2.1-3.2-2.25-1.85-5.45-1.85-1.667 0-3.1.5-.366.14-.75.3z"
                    />
                    <path
                        fill="#4C721B"
                        d="M42.55 137.65H43q2.7 0 4.6 1.7 1.164 1.072 1.6 2.4 1.024-.4 2.2-.4.871 0 1.65.2l5.65-19.25L35 103l-19.45 10.45q-1.185.392-2.25 1.2-.372.322-.75.7-1.788 1.788-2.2 4.15-2.863.013-4.95 2.05-1.273 1.304-1.75 2.9.291.25.55.5.101.096.2.2.052.047.1.1 1 1.05 1.55 2.4.65 1.6.3 2.85l.15-.15q.6-.65 1.2-.85.65-.25 2.2-.25 2.35 0 3.95 1 .877.546 1.4 1.35.186.257.3.55.55 1.084.55 2.55 0 1.748-.35 2.95-.066.232-.15.45v.5q.375-.119.65-.25.478-.11.75-.2.1-.05 1.2-.05 2.15 0 3.55.9.69.448 1.15 1.1.746 1.097.85 2.75 1.096-.35 2.35-.35h.5q-.5-1.144-.5-2.5 0-2.75 2.1-4.7 2.1-1.9 5.1-1.9 3 0 5.1 1.9 1.364 1.267 1.85 2.85 1.066-.423 2.3-.5m325 3.2q-.036-.278-.1-.55-.358-1.244-1-2.2-.317-.457-.7-.85-.057-.057-.15-.1-1.722-1.683-4.15-1.4.2-.695.2-1.5 0-1.8-1.05-3.2-.23-.33-.55-.65-.834-.834-1.85-1.25-.923-.35-2-.35-.56 0-1.1.1-1.302.251-2.4 1.15-.114-2.164-1.7-3.75-1.75-1.75-4.2-1.75t-4.2 1.75q-.512.512-.9 1.05-.038.09-.1.15-.25-2.253-1.15-3.65-.309-.537-.75-.95-1.45-1.4-4.1-1.4-1.65 0-2.9.55-.39.171-.7.35-.022.054-.05.05-15.197 22.312 0 44.6h.1l1.9.65q-.2-.933-.2-1.9 0-2.7 2.55-4 2.217-1.157 4.5-.6-.95-1.69-.95-3.8 0-3.2 2.25-5.45t5.45-2.25q1.274 0 2.4.35.024-.082.05-.2v-.15q0-2.85 2-4.9 2.05-2 4.9-2 2.85 0 4.85 2 .734.734 1.2 1.55.12-2.398 2.25-4.15.753-.619 1.6-1.05.384-.16.75-.3z"
                    />
                </g>
                <g id="PlaOut063__Layer2_0_FILL">
                    <path
                        fill="#8A796E"
                        d="M332.05 167.05h-279l-35.7 11.85H0v79.35h21.25v.8l6.2-5V254q165.05-3.877 330.1 0v.1l6.2 4.95v-.8H385V178.9h-17.35l-35.6-11.85m-.1 3l.35.1h-.35v-.1M22.25 180.6l.6-.2-1.55.55v-.05l.95-.3m30.8-10.6v.15h-.35l.35-.15z"
                    />
                    <path
                        fill="#E38626"
                        d="M53.05 170.15V170l-.35.15h.35m279.25 0l-.35-.1v.1h.35z"
                    />
                    <path
                        fill="#987260"
                        d="M22.85 180.4l-.6.2-.95.3v.05l1.55-.55z"
                    />
                    <path
                        fill="#4C721B"
                        d="M362.75 126.1q-.15-.054-.3-.1.146.158.3.25v-.15m7.1-4.5v-.1h-.05l.05.1z"
                    />
                </g>
                <g id="PlaOut063__Layer2_5_FILL">
                    <path
                        fill="#A65D39"
                        d="M118 41.45l-5.85-6.55v154l5.7-4.65.15-.05V41.45m154.85-6.55L267 41.45V184.2l.15.05 5.7 4.65v-154z"
                    />
                    <path
                        fill="#8C4C2D"
                        d="M267 41.45l5.85-6.55h-160.7l5.85 6.55h149z"
                    />
                </g>
                <g id="PlaOut063__Layer1_0_FILL">
                    <path
                        fill="#37565F"
                        d="M174.35 110l7-60.6H125.6l.3 66.85h21.45l27-6.25m7 15h-2.95q-21.544 10.949-35.15 0h-17.3l.15 36.75h13.3l19.15 5.8h22.8V125m22.3 0v7.2l6.25 24.6 12.2 10.75h36.75l.2-42.55h-17.1q-17.9 10.206-34.95 0h-3.35m38.45-75.6h-6.85l.05-.05h-.5l-.05.05h-31.1v66.7l-.05.1h.05v.05h7.4q19.943-6.351 34.95 0h13.1l.1-28.3-17.1-38.55z"
                    />
                    <path
                        fill="#F3E4C2"
                        d="M331.95 20.5l14.2-10.65V0H38.85v9.85l14.2 10.65v177H75.3l13.45-8.6q-10.013-80.18 0-161.9 104.91-6.962 207.5 0 .242 4.374.45 8.75 3.754 76.178-.45 153.15l13.45 8.6h22.25v-177z"
                    />
                </g>
                <g id="PlaOut063__Layer1_1_FILL">
                    <path
                        fill="#E2D2AC"
                        d="M85.6 24.05q-1.552-1.442-3.15-2.9-2.585-2.407-5.2-4.85l-1.95-1.8v183l13.45-8.6V27L87 25.35q-.304-.277-.6-.55-.382-.362-.8-.75m212.4 1.3L296.25 27v161.9l13.45 8.6v-183l-1.95 1.85q-2.6 2.4-5.25 4.8-1.55 1.45-3.15 2.95-.35.35-.75.7-.3.25-.6.55z"
                    />
                    <path
                        fill="#D0BF9A"
                        d="M331.95 14.3v6.2l14.2-10.65H38.85l14.2 10.65v-6.2H75.2l.1.2 1.95 1.8q2.615 2.443 5.2 4.85 1.598 1.458 3.15 2.9.418.388.8.75.296.273.6.55L88.75 27h207.5l1.75-1.65q.3-.3.6-.55.4-.35.75-.7 1.6-1.5 3.15-2.95 2.65-2.4 5.25-4.8l1.95-1.85.1-.2h22.15z"
                    />
                </g>
            </defs>
            <use xlinkHref="#PlaOut063__Layer3_0_FILL" />
            <use xlinkHref="#PlaOut063__Layer3_1_FILL" />
            <use xlinkHref="#PlaOut063__Layer3_1_MEMBER_0_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_0_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_1_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_2_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_3_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_4_FILL" />
            <use xlinkHref="#PlaOut063__Layer2_5_FILL" />
            <use xlinkHref="#PlaOut063__Layer1_0_FILL" />
            <use xlinkHref="#PlaOut063__Layer1_1_FILL" />
            <use xlinkHref="#PlaOut063__Layer1_2_FILL" />
            <use xlinkHref="#PlaOut063__Layer1_3_FILL" />
        </g></g>
    )
}
export default PlaOut063
