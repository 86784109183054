import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#8FAFCD"
}
function LegSpe004({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill="#CCC"
                    d="M300.9 339.15q.521-.869 2.1-3.65l-3.8 6.25q.85-1.25 1.7-2.6z"
                    id="LegSpe004__Layer5_0_FILL"
                />
                <path
                    fill={colors.col1n}
                    d="M208.45 0v.05q12.15 14.45 6.25 24.5-1.15 2.55-4.75 5.3-.05 0-.1.05-4.9 3.65-15.2 5-3.9.45-7.85 3.1-3.6 2.4-6.15 5.8-3.75 4.95-5.5 7.9-.6 1-1.05 1.95l-1.4 3.5q-.4.95-.8 2-3.35 7.95-7.65 12.1-5.1 4.9-11.9 4.9h-1.05q-6.15-.35-9.2-4.85-2.55-3.7-3.4-11.75-.05-.1-.05-.2l-.5.1q-62.4 13.15-127.7 0l-.5-.1-.1.1-.2.3q-.65 2.7-2.5 5.5-12.75 18.9-2.8 35 9.75 13.55 26.6 21.8 1.95.9 3.9 1.75 36.8 13.85 70.95 8.25 47.4-9.35 70.15-35.75 6.85-7.35 12.35-16.15 3-4.6 5.5-10.5.1-.2.15-.45 2.1-7.15 3.4-10.65 0-.15.05-.25.85-2.35 2.65-4.6.35-.5.8-1 2.15-2.45 4.85-4.15.85-.55 1.75-1l8.4-4.2q1-.45 2.85-1.9 2.9-2.3 4.6-3.75.85-1.1 1.6-2.15 7.95-12.45 0-24.45-4.1-6.45-12.4-9.7-2.1-.85-4.05-1.4z"
                    id="LegSpe004__Symbol_13_0_Layer0_0_FILL"
                />
            </defs>
            <use xlinkHref="#LegSpe004__Layer5_0_FILL" />
            <use
                xlinkHref="#LegSpe004__Symbol_13_0_Layer0_0_FILL"
                transform="translate(118.2 248.05)"
                id="LegSpe004__col1n"
            />
        </g></g>
    )
}
export default LegSpe004
