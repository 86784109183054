import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#252525",
    "col1n": "#434343",
    "col2d": "#D70076",
    "col2n": "#FF008C"
}
function HaiHig046({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.55.45Q65.6.2 65.7 0q-4 3.2-8.5 5.6l-.3.2Q50.25 9.25 41 11.75q-9.8 2.6-16.6 10.45-3.3 3.75-10 15.1-.95.8-1.8 1.7-.6.85.3 1.5.9.65 2.35 2.85 1.45 2.2-4.65 5.1-6.15 2.9-7.55 7.15l-.3 1q-.2.5-.3 1-.1.3-.2.65-.35 1.25-.65 2.6Q.3 66.6.05 73 0 74.45 0 75.9q0 3.2.25 6.35 0 .5.1 1.05 4.9 3.1 4.25 3.9l-.65.75.95.2q.25.05.5.15 3.3 1-.05 2.2-.05 1.15-.15 2.65-.55 9.7.2 12.1.35 1.1.65 2.15l.2.7q.45 1.6.75 2.95.75 3.1.95 5.1v.4q.05.35.05.7.3 3.7.2 6.7V124.65q-.05.1 0 .15-.05.65-.15 1.4-.15 1.45.55 2.65.7 1.15 3.45 2.5 2.8 1.3-2.05 5.05-4.85 3.7-5.3 6l-.1.3q-.3 1.1-.6 2.05-.5 1.85-.8 3.25-.15.6-.25 1.35-.15.8-.3 1.8-.2 1-.35 2.15-.5 3.25-1.05 7.8-.1 2.1-.3 5.2-.15 1.45-.25 3.1-.1 1.1-.15 2.35-.05.3-.05.6l-.05.7q5.4 10.3 2.7 15.75-2.7 5.45-2.3 7.45v.3q.2 1.6.5 3.15 2.15 11.75 9.35 19.5l1.1 1.1q2.8 2.75 6.25 4.95.75.3 1.55.6-2-2.85-2.25-8-.1-2.25.15-4.95l.1-1.2q.05-.65.15-1.2v.05q.05.1.15.25 2.9 5.55 8.55 8.45-1.2-3.6-1.4-5.7-.65-4-1.1-7.75-.55-4.65-.9-9-.2-2.5-.3-4.85-.1-1.9-3-6.25-2.95-4.35-.85-8.5 2.1-4.2 3.4-5.4 1.3-1.2 1.45-2.2l.1-.6q.2-1.25.45-2.45.2-1.1.45-2.15.4-1.6.8-3.1.05-.2.1-.35 1.85-6.55 3.05-12.8.3-1.75.6-3.4l.2-1.5q.05-.35.1-.65-1-3.25-1.9-6.35-.55-1.9-.35-2.2-.5-.4-1.25-1.05-.05-.1-.1-.15-.15-.1-.25-.2-2.35-2.35 1.25-4.05.25-.15.45-.2.1-.25.25-.5 3.05-6.55 3.05-6.65-.05-1.35-.15-2.7-.05-.5-.05-1-.05-.1-.05-.2v-.1q-.45-4.6-1.25-8.95-.1-.35-.15-.65l-.1-.3q-.3-1.15-.65-2.35-.05-.15-.05-.25l-.6-2q-.1-.2-.15-.35-.05-.1-.05-.15-.3-.85-.5-1.7l-.4-1.3q-.1-.6-.2-1.15-.15-.7-.25-1.35-.55-3-2.75-5.3-2.2-2.35-2.35-6.4-.15-4.05 5.6-10.4.2-.85.4-1.65 1.9-7.5 5.95-14.75 2.6-4.7 6.1-9.35.75-1.05 1.6-2.05 1.45-1.75 2.85-3.6-2.85-7.8 4.4-14.05 7.2-6.3 8.9-7.65 1.2-2.4 2.3-4.9 3.5-7.7 6.4-16.2m-46.3 200.9v.15h-.05q0-.1.05-.15z"
                    id="HaiHig046__Symbol_151_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M2.3 188.2q-.95.95-2.3 2.15 0 .55.05 1.15.1 2.35.3 4.85.35 4.35.9 9 .3 2.45.7 5 2.9 5.55 8.55 8.45-1.2-3.6-1.4-5.7-.65-4-1.1-7.75-.55-4.65-.9-9-.2-2.5-.3-4.85-2.85-2.2-4.5-3.3m1 12.7v.15h-.05q0-.1.05-.15m3.85-55.4q-.3-.6-.55-1.15-.3 1.65-.6 3.35-1.2 6.25-3.05 12.8-.05.15-.1.35-.4 1.5-.8 3.1-.25 1.05-.45 2.15l-.1.7q.7.85 1.75 2.05l5.1-2.75q.2-1.1.45-2.15.4-1.6.8-3.1.05-.2.1-.35 1.85-6.55 3.05-12.8.3-1.75.6-3.4l.2-1.5q.05-.35.1-.65-.35.2-.65.4-1.25.8-3.25 1.75-1.15.55-2.6 1.2m3.5-46.7q-.1-.6-.2-1.15L4.4 100.4q.15.7.4 1.4 0 .05.05.15.05.15.15.35l.6 2q0 .1.05.25.35 1.2.65 2.35l.1.3q.05.3.15.65.8 4.35 1.25 8.95v.1q0 .1.05.2 0 .5.05 1 .1 1.35.15 2.7v.2q0 .35.05.75l.3-.2q2.2-1.35 6.2-4.45-.05-.1-.05-.2v-.1q-.45-4.6-1.25-8.95-.1-.35-.15-.65l-.1-.3q-.3-1.15-.65-2.35-.05-.15-.05-.25l-.6-2q-.1-.2-.15-.35-.05-.1-.05-.15-.3-.85-.5-1.7l-.4-1.3m11.8-52.6q-1.5 1.1-6.7 3.2-3.1 4.15-5.45 8.4-4.05 7.25-5.95 14.75-.2.8-.4 1.65-.35 1.55-.6 3.1l7.35-3.1q.2-.85.4-1.65 1.9-7.5 5.95-14.75 2.6-4.7 6.1-9.35.75-1.05 1.6-2.05 1.45-1.75 2.85-3.6-2.6 1.5-5.15 3.4m20.75-30Q46.7 8.5 49.6 0q-4.65 11.5-12.4 23 1.75-.9 3.7-1.9 1.2-2.4 2.3-4.9z"
                    id="HaiHig046__Symbol_152_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M19.45 147.75q-1.05-1.2-1.75-2.05-1.2-1.45-1.6-2.15L4.45 149.1q-1.6-2.2-3.25-4.35-.15 1.45-.25 3.1-.1 1.1-.15 2.35-.05.3-.05.6l-.05.7q-1.3 10.3-.2 19.2.2 2 .6 4v.3l2.35-2.7q1.95-2.25 2.05-2.85.4.45 2 1.8 2.55 2.15 2.9 3.1 3.45-3 5.8-5.1 1.35-1.2 2.3-2.15 1.65 1.1 4.5 3.3-.1-1.9-.1-3.7-.2-7.95.6-14.75.1-.95.2-1.8.15-1.1.3-2.1l.1-.6q.2-1.25.45-2.45l-5.1 2.75M30.85 97q-.05-.5-.05-1-4 3.1-6.2 4.45l-.3.2q-1.8 1.1-3.4 1.8-.9.4-1.7.65-.15-.35-.2-.65-.9-2.7-1.7-4.15-1.95.85-8.85 4.1V103.1q-.05.1 0 .15-.05.65-.15 1.4-.15 1.45-.5 3.4-.15.7-.3 1.45-.55 2.8-1.45 6.45-.65 2.6-1.1 4.9l-.1.3q.3.85.65 2.05.15.6.3 1.25.4 1.7.45 3l.15.05q.1.05.1.2 5.2-2.25 9.8-4.5l1-.5q4.3-2.2 4.45-2.25.2.95 1.05 2.75v.05q.25.55.55 1.15 1.45-.65 2.6-1.2 2-.95 3.25-1.75.3-.2.65-.4.2-1.2.35-2.35.15-1.65.35-3.2.1-1.5.25-2.9.25-3.75.3-7.35v-.1-2.7L31 99.9v-.2q-.05-1.35-.15-2.7m-5.3-32.05q.05-6 1.35-11.85l-10.2 4.35q-.25 0-.45.1v-4.1q-9.6 3-15.65 8.3.1 1.6.35 3.2.05.6.15 1.15.2 1.55.5 3.1.2 1 .4 1.95.45 2.05 1 4.05.45 1.7 1 3.35.9 2.75 1.65 5.15 1.7-1.5 5.05-3.35 5.05-2.45 5.3-2.65.2 1.05.6 3.4l10.05-4.55q-.15-.7-.25-1.35-.55-3-.75-6-.15-2.15-.1-4.25M43.8 21.7Q50.4 13 55.85 2.5q.6-1.25 1.25-2.5-1.95 1-3.7 1.9-3.1 1.6-5.4 2.7-1.55.75-2.7 1.25-4.85 2.1-12.55 4.3.3-.6.55-1.2.5-1.15.85-2.1Q25 10.05 21.2 11.7q-1.95.85-3.65 1.9-1.6.95-2.9 2.15-.95.8-1.8 1.7-.6.85-1.35 1.85l-.4.6Q8.2 24 6.75 26.45 4.7 29.8 3.3 34.05l-.3 1q-.2.5-.3 1 3.45-2 7.3-3.5Q13.85 31 16.8 30q2.9-1.05 6.45-2.3h.25q-.15 1.3-.9 3.8-.05.1-.05.2 5.65-1.95 9.4-3.4 5.2-2.1 6.7-3.2 2.55-1.9 5.15-3.4z"
                    id="HaiHig046__Symbol_153_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M8 147.45q.2-1.25.45-2.45l-5.1 2.75q-1.05-1.2-1.75-2.05-.2.85-.35 1.75l-.1.6q-.15 1-.3 2.1-.1.85-.2 1.8-.8 6.8-.6 14.75 0 1.25.05 2.55 1.35-1.2 2.3-2.15 1.65 1.1 4.5 3.3-.1-1.9-.1-3.7-.2-7.95.6-14.75.1-.95.2-1.8.15-1.1.3-2.1l.1-.6M14.75 97q-.05-.5-.05-1-4 3.1-6.2 4.45l-.3.2q0 .9.05 1.8V105.25q-.05 3.6-.3 7.35-.15 1.4-.25 2.9-.2 1.55-.35 3.2-.15 1.15-.35 2.35-.05.3-.1.65l-.2 1.5v.05q.25.55.55 1.15 1.45-.65 2.6-1.2 2-.95 3.25-1.75.3-.2.65-.4.2-1.2.35-2.35.15-1.65.35-3.2.1-1.5.25-2.9.25-3.75.3-7.35v-.1-2.7l-.1-2.55v-.2q-.05-1.35-.15-2.7M10.8 53.1l-7.35 3.1q-.7 4.35-.75 8.75 0 2.1.15 4.25.15 3 .7 6 .1.65.25 1.35.1.55.2 1.15l.4 1.3.1.3 6.05-2.75q-.15-.7-.25-1.35-.55-3-.75-6-.15-2.15-.1-4.25.05-6 1.35-11.85m5.05-24.8q5.2-2.1 6.7-3.2 2.55-1.9 5.15-3.4Q34.3 13 39.75 2.5 40.35 1.25 41 0q-1.95 1-3.7 1.9-11.15 16.9-21.4 26.25-.05.05-.05.15z"
                    id="HaiHig046__Symbol_154_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#HaiHig046__Symbol_151_0_Layer0_0_FILL"
                transform="translate(88.55 90.5)"
                id="HaiHig046__col1n"
            />
            <use
                xlinkHref="#HaiHig046__Symbol_152_0_Layer0_0_FILL"
                transform="translate(104.5 90.95)"
                id="HaiHig046__col1d"
            />
            <use
                xlinkHref="#HaiHig046__Symbol_153_0_Layer0_0_FILL"
                transform="translate(88.3 112.05)"
                id="HaiHig046__col2n"
            />
            <use
                xlinkHref="#HaiHig046__Symbol_154_0_Layer0_0_FILL"
                transform="translate(104.4 112.05)"
                id="HaiHig046__col2d"
            />
        </g></g>
    )
}
export default HaiHig046
