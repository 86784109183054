import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1d": "#E0E0E0",
    "col1n": "#FFFFFF",
    "col2d": "#00B600",
    "col2l": "#17FF17",
    "col2n": "#00DE00"
}
function ObjFoo062({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M65.15 3.5q-1.65-.6-3.75-1.2-3.75-.95-8-1.6L48.5.15h-.35Q47.85.1 47.6.1q-.1-.05-.2-.05l.05.05L45.9 0 23.85.1q-6.7.6-12.25 1.95l-2 .5q-2.95.85-5 1.85L2 5.95q-.5.35-.9.75Q0 7.8 0 9.1q0 .4.15-.35Q0 10.8 0 11.8q0 .55.1 1.2 0 .3.05.55Q.4 15.5 1.4 17.4q1.65 3.3 5.45 6.15 1.6 1.2 3.6 2.3.05 0 .1.05 10.5 5.85 25.4 5.85 14.45 0 24.8-5.45.3-.25.65-.4 1.7-.95 3.15-1.95 6.7-4.75 7.3-11 .05-.35.05-.7v-.45q0-1-.1-1.8l-5.7-6.15q-.45-.2-.95-.35z"
                    id="ObjFoo062__Symbol_138_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col1d}
                    d="M61.4 15.85q1.1-.35 2.1-.6 5.2-1.65 7.25-3.6.2-.2.4-.5.4-.5.6-.95.05-.1.05-.2l-5.7-6.15q-.45-.2-.95-.35-1.65-.6-3.75-1.2-3.75-.95-8-1.6L48.5.15h-.35Q47.85.1 47.6.1q-.1-.05-.2-.05l.05.05L45.9 0 23.85.1q-6.7.6-12.25 1.95l-2 .5q-2.95.85-5 1.85L2 5.95q-.5.35-.9.75Q0 7.8 0 9.1q0 .4.15-.35.3 1.25 1.15 2.3.2.2.4.45l.05.05q1.55 1.65 4.55 2.9.4.15.9.35 1.5.55 3.35 1.05 4.1 1.05 8.85 1.8 1.5.2 3.05.35 3 .3 6.3.55 3.45.15 7.2.15 3.6 0 7.05-.15.7-.1 1.45-.15 4.3-.25 8.15-.8 1.55-.2 3.05-.45 3-.6 5.75-1.3z"
                    id="ObjFoo062__Symbol_139_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M68.75 9.9q-1.3-1.85-5.25-3.75.35.9.85 2.75.05.15.05.3.2 1.35.2 1.95 0 .5-.45 1.15-.3-.2-.7-.4-.45-.3-1-.55-5.1-2.75-5.5-2.95h-.1l-.35-.2-2.65 2.25-.25-.25-.05-.05q-.15-.15-.3-.25-.45-1.55-1.05-2.8-.25-.55-.5-1.05-1.4-2.3-3.25-3.55h-.3q-.35 1.4-1.1 2.6-.15.2-.25.4-.5.75-1.05 1.15-.2.1-.35.2l-.3.2q-.5-.95-.9-1.7-.5-.95-.85-1.55-.6.25-1.5.65-.1.05-.25.15l-1.65-1.2q-2.25-1.05-2.6-1.3-.55-.45-1.2-1.3-.5-.75-.55-.8-.45 1.55-1.15 2.25-.5.45-2.3.95-2.4.6-3.35.95-.4-.2-1-.45-1.65-.75-2.95-.75-1.35 0-1.55.5H22q.05.8.15 2v.6L20.7 5.3q-3.35-1.95-6.75-2.4h-.65L13 4.75l-.5 1.7q0 .1-.55 1-.55 1-.55 1.3-.8-.7-1.5-1.35-.6-.45-1.1-.95-.5.8-.9 1.45-.35.65-.55 1.15-.55 1.05-1.05 1.9-.6 1.15-.8 1.75-1.85-1.05-2.6-2.95-.5-1.3-.6-3Q.8 8.15.5 8.5q-.4.45-.4 1.7 0 .35.2 1.1 0 .15.05.3.25 1.05.25 1.7 0 .65-.1 1.05-.3 1.25-.5 2.5l.05.05q1.55 1.65 4.55 2.9.4.15.9.35 1.5.55 3.35 1.05 4.1 1.05 8.85 1.8 1.5.2 3.05.35 3 .3 6.3.55 3.45.15 7.2.15 3.6 0 7.05-.15.7-.1 1.45-.15 4.3-.25 8.15-.8 1.55-.2 3.05-.45 3-.6 5.75-1.3 1.1-.35 2.1-.6 5.2-1.65 7.25-3.6.2-.2.4-.5.4-.5.6-.95.05-.1.05-.2.1-.5.1-.9 0-.75-.35-1.4-.35-1.95-1.1-3.15z"
                    id="ObjFoo062__Symbol_140_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M10.8 11.5q.2-.15.55-.3-.25-.45-.35-.8-.05-.15-.1-.25-.35-.6-1.2-1.25Q8.55 8 7.45 7.15q-.4-.3-.8-.65-1.2-.95-2.25-1.95-.6-.45-1.1-.95-.5.8-.9 1.45-.35.65-.55 1.15Q1.3 7.25.8 8.1.2 9.25 0 9.85q.05 0 .1.05.25.15.6.3.1.05.25.1.65.25 1.1.45.2.1.4.25 3.3.8 5 2.65.25.3.85 1.2.5-.5 1-1.4 1-1.6 1.5-1.95M7.85 7.8h.1v.1q-.003-.097-.1-.1m22.8 2.2q.55.65 2.25 1.05t2.4.45q0-1-.25-1.95-.35-1.6-1.15-2.2-.35-.25-1.2-.4-.9-.15-1.9-.75-.9-.5-1.5-.95h.05l-.15-.15q-1.35-1.3-2.6-2.15-.7-.45-1.45-.85-.2-.1-.7-.3-.35-.15-1.15-.5-.4-.2-1-.45-2.5-1.7-5.75 0 0 .75.1 1.75 0 .95.1 2.3.05-.05.1 0l-.1 4.5q.2-.05.45-.05 1.2-.2 2.7-.4.75-.15 1.55-.3h1.4v.5q-.6 1.05-.95 2.65-.35 1.4-.35 2.35 0 .7.2 2.15.05.25.1.55h.2q.75.1 3.85.1-.25-2.2-.25-2.8 0-1.75.85-3.7.8-1.95 1.95-2.95 1.6 1.8 2.2 2.5m20.7-4.45l-.35-.2-5.5 4.8q-1.1.5-5.8-.35l-2.85 2.1q.35.05.65.1.65.1 1.45.25 1.35.25 3.2.8.5.05.85 1.05 0 .1.05.15.55 2 1.55 4 .45.95.8 1.85 1.55-.2 3.05-.45-.25-.6-.95-1.9-.75-1.3-.4-4.85l.05.4h.7q.95.55 1.8.95.05.05.1.05 3.35 1.65 5.15 1.75-.3-.85-.3-1.75v-.05q.05-1.5 2.25-3.1.45-.35.75-.6.2-.15.35-.3.35-.3.6-.6l.1-.2q-.3-.2-.7-.4-.45-.3-1-.55-5.1-2.75-5.5-2.95h-.1z"
                    id="ObjFoo062__Symbol_142_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M2.9 5.95q-.5-1.3-.6-3Q.8 4.35.5 4.7q-.4.45-.4 1.7 0 .35.2 1.1 0 .15.05.3Q.6 8.85.6 9.5q0 .65-.1 1.05-.3 1.25-.5 2.5l.05.05Q1.6 14.75 4.6 16q.25-.15.45-.25.95-.5 4.35-.8 3.55-.4 3.8-.5.25-.2.6-.55-.6-.9-.85-1.2-1.7-1.85-5-2.65-.2-.15-.4-.25-.45-.2-1.1-.45-.15-.05-.25-.1-.35-.15-.6-.3-.05-.05-.1-.05-1.85-1.05-2.6-2.95m17.85 13.6q3 .3 6.3.55 3.45.15 7.2.15 3.6 0 7.05-.15-1.75-4.1-3.4-4.1h-6.5q-3.1 0-3.85-.1h-.2q-1.8.1-3.45-.35-1.65-.4-1.75-.5-.15-.1-.35.6-.8 2.8-1.05 3.9M22.7 8.4q-.25 0-.45.05l.1-4.5q-.05-.05-.1 0-.55-.1-1.1-.15-2.45-.25-3.9-.25-1.55 0-2.85.55-.8.4-2.25 1.45.4.35.8.65 1.1.85 2.25 1.75.85.65 1.2 1.25.05.1.1.25.1.35.35.8 1.65-.5 3.4-1 1.7-.55 2.45-.85m21.5-6.85Q43.7.6 43.35 0q-.6.25-1.5.65-.1.05-.25.15-.55.25-.95.45-.1 0-.15.05-.8.35-1.1.55-2.15 1.15-4.7 2.3l.15.15h-.05q.6.45 1.5.95 1 .6 1.9.75.85.15 1.2.4l4.75-2.6.3-.2q.2-.1.45-.25.1-.05.15-.1h.05q-.5-.95-.9-1.7M41.15 4.7q-.003-.097-.1-.1h.1v.1m23.2.4q.05.15.05.3.2 1.35.2 1.95 0 .5-.45 1.15l-.1.2q-.25.3-.6.6-.15.15-.35.3-.3.25-.75.6-2.2 1.6-2.25 3.1v.05q0 .9.3 1.75l.2.5q.5.95 1.2 1.2 5.2-1.65 7.25-3.6.2-.2.4-.5.4-.5.6-.95 0-1.4-.2-2.5-.35-1.95-1.1-3.15-1.3-1.85-5.25-3.75.35.9.85 2.75z"
                    id="ObjFoo062__Symbol_141_0_Layer0_0_FILL"
                />
                <path
                    fill="red"
                    d="M286.3 264.8q-.35-.15-.7-.25l-.85 2.25q.297.155.85.9.608.736 2.2 2.95 1.639 2.269 2.2 4.35h2.25q.15 0 .25.05.4-2.7-.85-5.35-1.65-3.55-5.35-4.9z"
                    id="ObjFoo062__Layer1_0_FILL"
                />
                <path
                    fill="#FF7E7E"
                    d="M285.45 276.35l1.5-.6q1.7-.65 2.55-.7V275h.5q.45-2.25-.5-4.35-1.3-2.55-4.15-3.6-.3-.1-.6-.25l-3.8 10.45q.85-.15 2.6-.45 1.1-.25 1.9-.45z"
                    id="ObjFoo062__Layer1_0_MEMBER_0_FILL"
                />
                <path
                    fill="#D8FF8A"
                    d="M258.35 267.7q.55.15 1.1.35l.95-2.25q-3-1.35-6.1-.15-2.9 1.1-4.2 4.1-1.35 3.05-.2 6 .35.75.8 1.45l3.35-.85q-.25-.25-.35-.45-.3-.55.05-.8.3-.25 1.05-.1.65 0 1.3.45l.05.05.1-.2q.25-1.6.25-1.8.05-.2.05-.9 0-1-.3-1.85-.6-1.5-.8-2.3v-1.6q.95.45 2.9.85m-5.7 4.4q-.65-.4-.85-.85-.3-.6 0-.8.3-.3 1.1-.15.6.05 1.25.5.7.45.95.9.3.55 0 .8-.35.2-.95.25-.75-.15-1.5-.65z"
                    id="ObjFoo062__Layer1_1_MEMBER_0_FILL"
                />
                <path
                    fill="red"
                    d="M252.3 281.1q-3.9-.15-6.8 2.5-1.2 1.1-1.9 2.4 1.25.3 2.5.55.45-.646 2.8-1.15 2.35-.51 3.15-1.15.8-.586.95-.75l.05-2.35q-.35-.1-.75-.05z"
                    id="ObjFoo062__Layer1_2_FILL"
                />
                <path
                    fill="#FF7E7E"
                    d="M253 283.5q-.3.05-.65.05-3.05-.15-5.2 1.8-.6.55-1.05 1.2 3.55.7 6.75.85l.15-3.9z"
                    id="ObjFoo062__Layer1_2_MEMBER_0_FILL"
                />
                <path
                    fill="#D8FF8A"
                    d="M277.65 281.55q-1.5 2.8-.6 5.9 4.95-.35 9.3-1.3l-3.7-1.95q-.2.4-.5.8-.45.65-.9.8-.55.3-.75-.1-.25-.3-.1-1.1.15-.6.6-1.2l.05-.05-3.4-1.8z"
                    id="ObjFoo062__Layer1_3_MEMBER_0_FILL"
                />
                <path
                    fill="#FF7B00"
                    d="M263.55 270.1q.85.75 1.4 1.35 3.35-2.65 5.95-6.25l-1.35-1.4q-2.65 3.7-6 6.3z"
                    id="ObjFoo062__Layer1_4_FILL"
                />
                <path
                    fill="#FF7B00"
                    d="M290.95 280.35q-2.25-1.65-4.15-3.7l-1.55 1.15q2.7 3.05 6.1 5.25-.45-1.1-.25-2.6-.1-.05-.15-.1m-17-6.75q-2.45 5.85-6.85 10 2.1-.1 2.5.35 3.75-4.2 5.9-9.25l-1.55-1.1m-12.2 14.35q-1.85-2.75-3.15-6.05l-1.55 1q.95 2.55 2.4 4.9l2.3.15m-15.95-7.9q.1-.15.25-.35-4.5.15-9.15-1.15l-.1 1.9q3.85 1.1 7.85 1.2.25-.4 1.15-1.6z"
                    id="ObjFoo062__Layer1_5_FILL"
                />
                <g id="ObjFoo062__Layer1_1_FILL">
                    <path
                        fill="#F9FFEE"
                        d="M256 275.85l.15-.35-.05-.05q-.65-.931-1.5-1.3-.798-.325-1.55.4-.702.719 0 1.1.75.444 1 .7l1.95-.5m-2.95-6.05q-.952.35-1.25.65-.295.205-.2.95.086.784.7 1.35.614.566 1.5.25.945-.305 1.3-.5.301-.25.2-1.1-.055-.85-.7-1.4-.596-.55-1.55-.2z"
                    />
                    <path
                        fill="#227000"
                        d="M261.05 264.05q-3.6-1.6-7.3-.15-3.7 1.4-5.3 5.05-1.55 3.55-.1 7.35.3.7.65 1.35l1.7-.45q-.453-.701-.5-2.8-.043-2.049.5-4.35.596-2.245 3.45-3.15 2.844-.863 6.25-1.1l.75-1.7q-.05-.05-.1-.05z"
                    />
                </g>
                <g id="ObjFoo062__Layer1_3_FILL">
                    <path
                        fill="#F9FFEE"
                        d="M282.65 284.2l-1.6-.85-.05.05q-1.122.555-1.35 1.25-.233.696.4 1.05.649.398 1.2.45.542.109.85-.7.362-.762.55-1.25z"
                    />
                    <path
                        fill="#227000"
                        d="M276.05 280.7q-.1 0-.1.05-1.7 3.25-.8 6.8l1.9-.1q1.406-3.1.6-5.9l-1.6-.85z"
                    />
                </g>
            </defs>
            <use
                xlinkHref="#ObjFoo062__Symbol_138_0_Layer0_0_FILL"
                transform="translate(229.1 269.25)"
                id="ObjFoo062__col1n"
            />
            <use
                xlinkHref="#ObjFoo062__Symbol_139_0_Layer0_0_FILL"
                transform="translate(229.1 269.25)"
                id="ObjFoo062__col1d"
            />
            <use
                xlinkHref="#ObjFoo062__Symbol_140_0_Layer0_0_FILL"
                transform="translate(230.8 263.9)"
                id="ObjFoo062__col2n"
            />
            <use
                xlinkHref="#ObjFoo062__Symbol_142_0_Layer0_0_FILL"
                transform="translate(236.3 266.75)"
                id="ObjFoo062__col2d"
            />
            <use
                xlinkHref="#ObjFoo062__Symbol_141_0_Layer0_0_FILL"
                transform="translate(230.8 267.7)"
                id="ObjFoo062__col2l"
            />
            <use xlinkHref="#ObjFoo062__Layer1_0_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_0_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_1_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_1_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_2_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_2_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_3_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_3_MEMBER_0_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_4_FILL" />
            <use xlinkHref="#ObjFoo062__Layer1_5_FILL" />
        </g></g>
    )
}
export default ObjFoo062
