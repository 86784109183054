import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#000000"
}
function FacNos017({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M.7 24.05q-.25 0-.45.15-.25.2-.25.45Q-.1 25.9.5 27v-.05q.7 1.4 2.2 1.4.35 0 1.2-.3.5-.2.75-.2.4.05 1.4.75l.05.05q1.35 1.05 2.25 1 .95.05 2.4-1v-.05q.9-.7 1.35-.75.15 0 .35.1h.05q.2.05.55.15.75.25 1.15.25 1.5 0 2.35-1.35h.05q.55-1.1.45-2.35 0-.25-.2-.45-.25-.15-.5-.15t-.45.25q-.15.2-.15.45.1.85-.3 1.6v-.05q-.45.75-1.25.75-.25 0-.75-.2h-.05q-.35-.131-.55-.15-.4-.15-.75-.15-.75-.05-2.15 1.05-.95.7-1.6.75-.6-.05-1.5-.75-1.5-1.1-2.2-1.05-.45 0-1.2.3-.5.2-.75.2-.7 0-1.05-.7-.45-.75-.35-1.6 0-.25-.15-.45-.2-.25-.45-.25M7 0q-.25 0-.45.25-.15.2-.15.45.65 9.75-2.95 18.2-.1.25 0 .5t.35.35q.25.1.5 0t.35-.35Q8.35 10.65 7.7.6q0-.25-.2-.45Q7.25 0 7 0z"
                    id="FacNos017__Symbol_29_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#FacNos017__Symbol_29_0_Layer0_0_FILL"
                transform="translate(184 179.6)"
                id="FacNos017__col1n"
            />
        </g></g>
    )
}
export default FacNos017
