import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#FFFF00",
    "col2d": "#C40000",
    "col2n": "#FF0000"
}
function ShiSpe030({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M101.05 33.5q-6.7-15.15-6.7-15.2-11.75 1.35-27.75-2.5h-.2q-11.85 3.5-26.7 3.85-1.65.3-8.05-1.15Q25.2 17 21 12.5q-4.2-4.55-4.3-4.65L8.45 0q2.95 9.15 2.4 22.3l-.1 2.4q-.05.15-.05.35-.15 2.15-.4 4.45-.05.4-.1.85l-2.7 14q-.55 2.7-1.1 5.45L2.95 64.1Q1.95 68 1 71.9l-.95 3.6v.25L0 75.8h.2q.25.1.5.15H.85q1.95.85 3.2 1.1 1.2.25 4.65 1.15 3.4.9 7.15 1.6 3.75.7 5.25.85 1.5.15 4.05.5 2.5.35 6.2.45 19.75-2.2 35.05 2.3 17.8 1.2 35.05-2.25 2.4-9.15 4.35-21.1 1.9-11.95-4.75-27.05z"
                    id="ShiSpe030__Symbol_390_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M69.05 15.8h-.25q-15.3 3.7-26.7 2.6-.5-.05-.95-.1-.05.05-.05.1-6.25 26.35-7.2 42.9-.6 10.2.1 20.3-3.75-.5-7.5-1.05-1.1-.2-2.25-.4l-2.9-.5q-6.5-1.15-13-2.6-.35-.05-.7-.15-2.1-.45-4.15-.95H3.35q-.25-.05-.5-.15h-.2l.05-.05v-.25l-1.6 1.6q-3.6 3.95 4.75 6.2l1.8.4q.75.15 1.5.35 3 .65 6.05 1.25 18.2 3.65 36.3 4.85 1.4.05 2.8.15 7.75.1 12.55-4.15l2.2-2.25V15.8m55.4 4.1Q124.25 8.3 127 0l-8.3 7.85q-.1.1-.2.25-7.9 8.6-21.5 10.2v.1q6.25 26.35 7.2 42.9.6 10.25-.1 20.35-17.5 2.25-35.05 2.25l2.55 2.55q4.75 3.95 12.2 3.85 1.2-.1 2.4-.1.2-.05.4-.05 21.05-1.4 42-6.05.9-.2 1.75-.4l.5-.1q9.8-2.5 5.6-6.85l-1.15-1.15v-.1l-6-24.25q-.65-3.1-1.3-6.15l-2.85-14.75q-.05-.45-.1-.85-.25-2.3-.4-4.45-.1-1.4-.15-2.75-.05-1.2-.05-2.4z"
                    id="ShiSpe030__Symbol_391_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2d}
                    d="M66.4 9.4l1-1q-17 0-34.05-2.15-.5-.1-1-.15-3.75-.5-7.5-1.05-1.1-.2-2.25-.4l-2.9-.5Q13.2 3 6.7 1.55 6.35 1.5 6 1.4 3.9.95 1.85.45H1.7Q1.45.4 1.2.3H1l.05-.05V0L0 1.05l.9.2h.15L1 1.3h.2q.05.05.15.05.15.05.35.1H1.85q2.05.5 4.15.95 3.75.85 7.55 1.6 26.5 5.3 52.85 5.4M134.55 1l-.9-.9.05.2h-.2q-.25.1-.5.15H132.85q-2.05.5-4.15.95-.35.1-.65.15-5.2 1.15-10.35 2.15-4.85.9-9.7 1.65-2.75.4-5.55.8Q84.95 8.4 67.4 8.4l1 1q30.25-.1 60.3-7 2.1-.45 4.15-.95l1.7-.45z"
                    id="ShiSpe030__Symbol_392_0_Layer0_0_FILL"
                />
                <path
                    fill="#FFF"
                    d="M132.45 228.6l-.05-.05q-.073-.062-.2-.15 1.087 4.662 3.7 8.65 2.3 3.55 5.85 6.7.55.5 1.1.95 8.6 7.2 20 9 .2 0 .4.05 3.5.5 7.3.5 10.702 0 20.7-4.25v.1q.05-.05.15-.1.5-.25 1-.45.523.233 1.05.45.075.034.15.05 9.993 4.2 20.7 4.2 3.8 0 7.3-.5.2-.05.4-.05 11.4-1.8 20-9 .55-.45 1.1-.95 3.55-3.15 5.85-6.7 2.544-3.914 3.6-8.45l-1.85 1.4q-.297.231-.6.45-1.07.831-2.2 1.65l-1.55 1.15q-13.387 9.253-28.95 13.25-8.152 2.097-16.9 2.75l-1.1.05q-2.179.13-4.4.15-.946.046-1.9.05h-1.2q-3.193-.014-6.3-.2l-1.1-.05q-25.45-1.9-45.85-16l-1.55-1.15q-.252-.182-.5-.4-1.184-.832-2.3-1.7l-1.85-1.4z"
                    id="ShiSpe030__Layer3_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#ShiSpe030__Symbol_390_0_Layer0_0_FILL"
                transform="translate(126.1 233.7)"
                id="ShiSpe030__col1n"
            />
            <use
                xlinkHref="#ShiSpe030__Symbol_391_0_Layer0_0_FILL"
                transform="translate(123.45 233.7)"
                id="ShiSpe030__col2n"
            />
            <use
                xlinkHref="#ShiSpe030__Symbol_392_0_Layer0_0_FILL"
                transform="translate(125.1 309.2)"
                id="ShiSpe030__col2d"
            />
            <use xlinkHref="#ShiSpe030__Layer3_0_FILL" />
        </g></g>
    )
}
export default ShiSpe030
