import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#999999",
    "col1s": "#434343",
    "col2l": "#999999",
    "col2n": "#666666"
}
function PlaFor040({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M715.85 715.85V0H0v715.85h715.85M515.2 504.8H200.55V158.2q159.3-11.75 314.65 0v346.6z"
                    id="PlaFor040__Symbol_82_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M9.65 4v-.15-.15q0-1.55-1.45-2.65Q6.85 0 4.8 0q-2 0-3.4 1.05Q.15 2 .05 3.35H0V352.8h9.6V4.5q.05-.2.05-.5m41.4.5q.05-.2.05-.5v-.15-.15q0-1.55-1.45-2.65Q48.25 0 46.25 0T42.8 1.05q-1.2.95-1.3 2.3h-.05V352.8H51V21.7h.05V4.5m41.5-.8q0-1.55-1.4-2.65-.7-.5-1.55-.75-.85-.3-1.85-.3-.9 0-1.65.2-1 .25-1.8.85-1.25.95-1.35 2.3h-.05V352.8h9.55V21.7h.05V4.5q.05-.2.05-.5v-.15-.15m41.4.8q.05-.2.05-.5v-.15-.15q0-1.55-1.45-2.65Q131.2 0 129.15 0q-2 0-3.45 1.05-1.2.95-1.3 2.3h-.05V352.8h9.7V5.95h-.1V4.5m41.5 0q.05-.2.05-.5v-.15-.15q0-1.55-1.4-2.65Q172.7 0 170.7 0t-3.45 1.05Q166 2 165.9 3.35h-.05V352.8h9.7V5.95h-.1V4.5M217 3.7q0-1.55-1.45-2.65Q214.2 0 212.15 0q-2 0-3.45 1.05-1.2.95-1.3 2.3h-.05V352.8h9.55V21.7h.05V4.5q.05-.2.05-.5v-.15-.15m41.45 0v-.35l-.1-.4q-.25-1.1-1.3-1.9-.85-.65-1.9-.85-.25-.1-.5-.1-.5-.1-1-.1-.55 0-1 .1-.25 0-.45.05-1.15.25-2 .9-1.05.8-1.3 1.9-.05.2-.05.4h-.05V352.8h9.6V4.5q.05-.2.05-.5v-.15-.15z"
                    id="PlaFor040__Symbol_86_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2l}
                    d="M3.75 1.55V1.2l-.1-.4Q1.8-.8.1.8q-.05.2-.05.4H0v349.65h3.7V2.35q.05-.2.05-.5V1.7v-.15m41.5.15v-.15-.35l-.1-.4Q43.3-.8 41.6.8q-.05.2-.05.4h-.05v349.65h3.7V2.35q.05-.2.05-.5V1.7m41.5.15V1.7v-.15-.35l-.1-.4Q84.8-.8 83.1.8q-.05.2-.05.4H83v349.65h3.7V2.35q.05-.2.05-.5m41.5 0V1.7v-.15-.35l-.1-.4q-1.85-1.6-3.55 0-.05.2-.05.4h-.05v349.65h3.7V2.35q.05-.2.05-.5m41.5-.3V1.2l-.1-.4q-1.85-1.6-3.55 0-.05.2-.05.4H166v349.65h3.7V2.35q.05-.2.05-.5V1.7v-.15m41.5 0V1.2l-.1-.4q-1.85-1.6-3.55 0-.05.2-.05.4h-.05v349.65h3.7V2.35q.05-.2.05-.5V1.7v-.15m41.4-.75q-1.85-1.6-3.55 0-.05.2-.05.4H249v349.65h3.7V2.35q.05-.2.05-.5V1.7v-.15-.35l-.1-.4z"
                    id="PlaFor040__Symbol_87_0_Layer0_0_FILL"
                />
                <g
                    id="PlaFor040__Symbol_83_0_Layer0_0_FILL"
                    fill={colors.col1s}
                >
                    <path
                        fillOpacity={0.502}
                        d="M10.2 10.25L0 .05V357.1h10.2V10.25M335.1 0l-10.25 10.25V357.1h10.25V0z"
                    />
                    <path d="M324.85 10.25L335.1 0 0 .05l10.2 10.2h314.65z" />
                </g>
            </defs>
            <use
                xlinkHref="#PlaFor040__Symbol_82_0_Layer0_0_FILL"
                transform="translate(-165.4 -126.3)"
                id="PlaFor040__col1n"
            />
            <use
                xlinkHref="#PlaFor040__Symbol_83_0_Layer0_0_FILL"
                transform="translate(24.95 21.65)"
                id="PlaFor040__col1s"
            />
            <use
                xlinkHref="#PlaFor040__Symbol_86_0_Layer0_0_FILL"
                transform="translate(63.3 25.75)"
                id="PlaFor040__col2n"
            />
            <use
                xlinkHref="#PlaFor040__Symbol_87_0_Layer0_0_FILL"
                transform="translate(66.15 27.9)"
                id="PlaFor040__col2l"
            />
        </g></g>
    )
}
export default PlaFor040
