import { AssetSvgProps } from "../../../shared/assets"

export const defaultColors = {
    "col1n": "#4C657B",
    "col2n": "#000000"
}
function PlaFor041({ colors = defaultColors, className, onClick, outerTransform, transform, startDrag }: AssetSvgProps) {
    return (
        <g transform={outerTransform}><g className={className} onClick={onClick} transform={transform} onMouseDown={startDrag} onTouchStart={startDrag}><defs>
                <path
                    fill={colors.col1n}
                    d="M877.3 595.4V452.45H0V595.4h877.3m0-452.45V0H0v142.95h877.3z"
                    id="PlaFor041__Symbol_88_0_Layer0_0_FILL"
                />
                <path
                    fill={colors.col2n}
                    d="M877.3 17.05V0H0v17.05h.05v24H0v7.4h243.85V350.9H0v7.4h.05v23.55H0v17.05h877.3v-17.05h-.05V358.3h.05v-7.4H633.8V48.45h243.5v-7.4h-.05v-24h.05m-8.25 0v24h-24v-24h24m-32.2 24h-24v-24h24v24m-56.05 0v-24h23.85v24H780.8m-8.35-24v24h-23.6v-24h23.6m-31.8 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m8.2 364.8V358.3h24v23.55h-24m-8.2 0h-24V358.3h24v23.55m40.4 0V358.3h24v23.55h-24m32.2 0V358.3h23.6v23.55h-23.6m31.95-23.55h23.85v23.55H780.8V358.3m64.25 23.55V358.3h24v23.55h-24m-8.2 0h-24V358.3h24v23.55m-216.8-364.8h24v24h-24v-24m-8.2 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m-56.2 0h24v24h-24v-24m-40.4 0v24h-24v-24h24m8.2 24v-24h24v24h-24m-40.4-24v24h-24v-24h24m-32.2 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m8.2 364.8V358.3h24v23.55h-24m-8.2 0h-24V358.3h24v23.55m40.4 0V358.3h24v23.55h-24m32.2 0V358.3h24v23.55h-24m56.2-23.55v23.55h-24V358.3h24m40.4 23.55V358.3h24v23.55h-24m-32.2-23.55h24v23.55h-24V358.3m64.4 23.55V358.3h24v23.55h-24m32.2 0V358.3h24v23.55h-24m56.2-23.55v23.55h-24V358.3h24M616.3 48.45V350.9H261.35V48.45H616.3m-326.45-31.4v24h-24v-24h24m8.2 24v-24h24v24h-24m-40.4-24v24h-24v-24h24m-32.2 0v24h-24v-24h24m-32.2 0v24h-24v-24h24m-64.4 0v24h-24v-24h24m8.2 24v-24h24v24h-24m-40.4-24v24h-24v-24h24m-32.2 0v24h-24v-24h24m-56.2 24v-24h24v24h-24m32.2 340.8V358.3h24v23.55h-24m-8.2 0h-24V358.3h24v23.55m40.4 0V358.3h24v23.55h-24m32.2 0V358.3h24v23.55h-24m56.2-23.55v23.55h-24V358.3h24m40.4 23.55V358.3h24v23.55h-24m-8.2 0h-24V358.3h24v23.55m40.4 0V358.3h24v23.55h-24m32.2 0V358.3h24v23.55h-24m56.2-23.55v23.55h-24V358.3h24z"
                    id="PlaFor041__Symbol_89_0_Layer0_0_FILL"
                />
            </defs>
            <use
                xlinkHref="#PlaFor041__Symbol_88_0_Layer0_0_FILL"
                transform="translate(-246.35 -105.45)"
                id="PlaFor041__col1n"
            />
            <use
                xlinkHref="#PlaFor041__Symbol_89_0_Layer0_0_FILL"
                transform="translate(-246.35 -6.95)"
                id="PlaFor041__col2n"
            />
        </g></g>
    )
}
export default PlaFor041
